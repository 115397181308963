import _get from 'lodash/get';
import _map from 'lodash/map';
import http from '../../api/requests/knownFor';
import normalize from '../../utils/normalizeJson';
import { events } from './reducers';

export const fetchKnownFor = keyWindow => dispatch => {
  dispatch(events.fetchStarted({ keyWindow }));
  return http
    .fetchKnownFor()
    .then(response => {
      const safeData = _get(response.data, 'data', []);
      const ids = _map(safeData, 'id');
      const data = normalize(response.data);
      return dispatch(
        events.changed({
          keyWindow,
          keyWindowMeta: { ids },
          byId: data.known_for,
        })
      );
    })
    .catch(err => dispatch(events.fetchFailed({ keyWindow, data: err.message })));
};
