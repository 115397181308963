import http from '../../api/requests/google_maps';
import { events } from './reducers';

// gets token from the api and stores it in the redux store and in cookie
export const getElevation = (latitude, longitude) => dispatch => {
  return http.fetchElevation({ latitude, longitude }).then(response => {
    if (response.data.results.length === 0) {
      // eslint-disable-next-line no-console
      console.error(`OpenElevation without results. status=${response.data.status}`);
    } else {
      dispatch(events.changed(response.data.results[0].elevation));
    }
  });
};
