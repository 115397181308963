import _keys from 'lodash/keys';
import http from '../../api/requests/travelers';
import normalize from '../../utils/normalizeJson';
import { events } from './reducers';

export const fetchUserScreenShots = (user, keyWindow) => dispatch => {
  dispatch(events.fetchStarted({ keyWindow }));
  return http
    .fetchUserScreenShots(user)
    .then(response => {
      const data = normalize(response.data);
      return dispatch(
        events.changed({
          keyWindow,
          keyWindowMeta: { ids: _keys(data.screen_shot) },
          byId: data.screen_shot,
        })
      );
    })
    .catch(err => dispatch(events.fetchFailed({ keyWindow, data: err.message })));
};

export const generateUserScreenShots = (user, destinationType) => () => {
  return http.generateUserScreenShots(user, destinationType);
};
