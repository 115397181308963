import { getApiClient, api_version } from '../..';
// import { getApiClient } from '../..';

const fetchMention = async id => {
  const url = `${api_version}mentions/${id}`;
  return getApiClient().get(url);
};

export default {
  fetchMention,
};
