import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isFetching: false,
  error: null,
  elevation: null,
};

const slice = createSlice({
  name: 'google_maps',
  initialState,
  reducers: {
    fetchStarted: state => {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    fetchFailed: (state, action) => {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    },
    changed: (state, action) => {
      return {
        ...state,
        isFetching: false,
        error: null,
        elevation: action.payload,
      };
    },
  },
});

export const { reducer, actions: events } = slice;
