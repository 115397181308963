import _keys from 'lodash/keys';
import httpBookmarks from '../../api/requests/bookmarks';
import normalize from '../../utils/normalizeJson';
import { events as destinationEvents } from '../destinations/reducers';
import { events } from './reducers';

export const fetchMapBookmarks = (slug, destinationType, keyWindow) => dispatch => {
  dispatch(events.fetchStarted({ keyWindow, keyWindowMeta: { ids: [] } }));
  return httpBookmarks
    .fetchUserBookmarks(slug, destinationType)
    .then(response => {
      const data = normalize(response.data);

      dispatch(
        events.changed({
          keyWindow,
          keyWindowMeta: { ids: _keys(data.bookmark) },
          byId: data.bookmark,
        })
      );

      return dispatch(
        destinationEvents.changed({
          keyWindow,
          keyWindowMeta: { ids: _keys(data.destination) },
          byId: data.destination,
        })
      );
    })
    .catch(err => {
      return dispatch(
        events.fetchFailed({ keyWindow, keyWindowMeta: { ids: [] }, data: err.message })
      );
    });
};

export const createBookmark = (destination, keyWindow) => dispatch => {
  dispatch(events.fetchStarted({ keyWindow }));
  return httpBookmarks
    .createBookmark(destination)
    .catch(err => dispatch(events.fetchFailed({ keyWindow, data: err.message })));
};

export const removeBookmark = (destination, keyWindow) => dispatch => {
  dispatch(events.fetchStarted({ keyWindow }));
  return httpBookmarks
    .removeBookmark(destination)
    .catch(err => dispatch(events.fetchFailed({ keyWindow, data: err.message })));
};
