import _groupBy from 'lodash/groupBy';
import selectorsBuilder from '../_core/selectors';

const baseSelectors = selectorsBuilder('checkins');

const getOldCheckinsByKeyWindow = keyWindow => store => {
  const result = baseSelectors.getCheckinsByKeyWindow(keyWindow)(store);
  const checkins = result.data;
  const checkinsGroup = _groupBy(checkins, item => item.attributes.destination_type);
  const continents = checkinsGroup['continent'] || [];
  const countries = checkinsGroup['country'] || [];
  const usStates = checkinsGroup['state'] || [];
  const cities = checkinsGroup['city'] || [];
  return {
    ...result,
    continents,
    countries,
    usStates,
    cities,
  };
};

const getLeaderboardByWindow = keyWindow => store => {
  const windowInfo = store.checkins[keyWindow] || { isFetching: false, leaderboard: {} };
  return {
    loading: windowInfo.isFetching,
    leaderboard: windowInfo.leaderboard,
  };
};

export default {
  ...baseSelectors,
  getOldCheckinsByKeyWindow,
  getLeaderboardByWindow,
};
