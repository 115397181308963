import { getApiClient } from '../..';

const fetchObject = async key => {
  const response = await getApiClient().get('/meta-tags', {
    urlParams: { key },
    timeout: 30000,
  });
  return response;
};

export default {
  fetchObject,
};
