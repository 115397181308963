import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isUndefined from 'lodash/isUndefined';
import _keyBy from 'lodash/keyBy';
import _keys from 'lodash/keys';
import _reduce from 'lodash/reduce';
import _values from 'lodash/values';
import { batch } from 'react-redux';
import http from '../../api/requests/destinations';
import normalize from '../../utils/normalizeJson';
import { events as covidCaseEvents } from '../covidCases/reducers';
import { events as knownForEvents } from '../knownFor/reducers';
import { events as mentionEvents } from '../mentions/reducers';
import { events as photoEvents } from '../photos/reducers';
import { events as userKnownForEvents } from '../userKnownFor/reducers';
import { events as userEvents } from '../users/reducers';
import { events } from './reducers';
import keyWindows from '../../constants/keyWindows';

const extractResult = (response, checked) => {
  const safeData = _get(response.data, 'data', []);
  const result = _reduce(
    safeData,
    (previous, current) => {
      previous.ids.push(current.id);
      if (checked || current.attributes.user_checked_in) {
        if (previous.checkIns[current.attributes.destination_type]) {
          previous.checkIns[current.attributes.destination_type].push({ id: current.id, countable: current.attributes.countable });
        }
      }

      return previous;
    },
    { ids: [], checkIns: { City: [], Country: [], State: [] } }
  );

  return result;
};
const extractSuggestedPlaces = res => {
  const safeData = _get(res, 'data', []);
  const result = _reduce(
    safeData,
    (previous, current) => {
      previous.suggestedPlaces[current.attributes.destination_type].push(current.id);
      return previous;
    },
    { ids: [], suggestedPlaces: { City: [], Country: [] } }
  );
  return result;
};

export const searchByBrowseDestinations = (filters, page, keyWindow) => dispatch => {
  dispatch(events.fetchStarted({ keyWindow, keyWindowMeta: { filters, ids: [] } }));
  return http
    .searchByBrowseDestinations(filters, page)
    .then(response => {
      const result = extractResult(response);
      const data = normalize(response.data);
      dispatch(
        photoEvents.changed({
          keyWindow,
          byId: data.photo,
        })
      );

      dispatch(
        knownForEvents.changed({
          keyWindow,
          byId: data.known_for,
        })
      );

      dispatch(
        events.setRawCachedCheckins({
          keyWindow,
          ...result.checkIns,
        })
      );

      return dispatch(
        events.changed({
          keyWindow,
          keyWindowMeta: {
            ids: result.ids,
            pagination: response.data.meta,
          },
          byId: data.destination,
        })
      );
    })
    .catch(err => {
      return dispatch(
        events.fetchFailed({ keyWindow, keyWindowMeta: { ids: [] }, data: err.message })
      );
    });
};

export const fetchDestination = (slug, keyWindow) => dispatch => {
  dispatch(events.fetchStarted({ keyWindow }));
  return http
    .fetchDestination(slug)
    .then(response => {
      const data = normalize(response.data);
      // find main destination
      const destination = _values(data.destination).find(
        p => p.attributes.slug === slug || p.id === slug
      );
      if (_isUndefined(destination))
        throw new Error(`response does not contain destination with slug/id=${slug}`);

      return batch(() => {
        dispatch(
          events.changed({
            keyWindow,
            keyWindowMeta: { id: destination.id },
            byId: data.destination,
          })
        );

        dispatch(covidCaseEvents.changed({ keyWindow, byId: data.covid_case }));
        dispatch(knownForEvents.changed({ keyWindow, byId: data.known_for }));
        dispatch(
          userKnownForEvents.changed({
            keyWindow,
            byId: _keyBy(response.data.data.relationships.user_known_for.data, 'id'),
          })
        );
        dispatch(photoEvents.changed({ keyWindow, byId: data.photo }));
        dispatch(userEvents.changed({ keyWindow, byId: data.user }));

        if (destination.attributes.user_checked_in) {
          dispatch(events.addCachedCheckins({ destination }));
        }

        dispatch(mentionEvents.changed({ keyWindow, byId: data.mention }));
      });
    })
    .catch(err => {
      dispatch(events.fetchFailed({ keyWindow, data: err.message }));
      throw err;
    });
};

export const searchByMyTravelMap = (filters, page, keyWindow) => dispatch => {
  const isAutoComplete = _isEmpty(filters.id) && !_isEmpty(filters.name);
  const keyWindowMeta = { filters, nextPage: page, autoComplete: isAutoComplete };
  if (page <= 1) {
    keyWindowMeta.ids = [];
  }

  const perPage = ['Country', 'State'].includes(filters.destination_type) ? 252 : 10;

  const action = () =>
    isAutoComplete
      ? http.autoComplete(filters.name, false)
      : http.searchByMyTravelMap(filters, page, perPage);

  dispatch(events.fetchStarted({ keyWindow, keyWindowMeta }));
  return action()
    .then(response => {
      const result = extractResult(response);
      const data = normalize(response.data);
      dispatch(
        events.changed({
          append: page > 1,
          keyWindow,
          keyWindowMeta: {
            ids: _isEmpty(filters.id) ? result.ids : result.ids.filter(id => id !== `${filters.id}`),
            pagination: response.data.meta,
            autoComplete: isAutoComplete,
          },
          byId: data.destination,
        })
      );

      // dispatch(events.clearOrderDestinations({ originalIds: result.ids, keyWindow }));

      dispatch(
        photoEvents.changed({
          keyWindow,
          byId: data.photo,
        })
      );

      dispatch(
        events.setRawCachedCheckins({
          keyWindow,
          ...result.checkIns,
        })
      );

      return dispatch(
        knownForEvents.changed({
          keyWindow,
          byId: data.known_for,
        })
      );
    })
    .catch(err => {
      return dispatch(
        events.fetchFailed({ keyWindow, keyWindowMeta: { ids: [] }, data: err.message })
      );
    });
};

export const orderDestinationsMyTravelMap = (order, keyWindow) => dispatch => {
  return dispatch(events.orderDestinations({ order, keyWindow }));
};

export const searchByDestinationType = (destination_type, keyWindow) => {
  return searchByMyTravelMap({ destination_type }, 1, keyWindow);
};

export const searchByAutoComplete = (query, keyWindow) => dispatch => {
  dispatch(events.fetchStarted({ keyWindow, keyWindowMeta: { query } }));
  return http
    .autoComplete(query)
    .then(response => {
      const result = extractResult(response);
      const data = normalize(response.data);

      dispatch(
        events.changed({
          keyWindow,
          keyWindowMeta: {
            ids: result.ids,
            pagination: response.data.meta,
          },
          byId: data.destination,
        })
      );

      dispatch(
        photoEvents.changed({
          keyWindow,
          keyWindowMeta: { ids: _keys(data.photo) },
          byId: data.photo,
        })
      );

      dispatch(
        events.setRawCachedCheckins({
          keyWindow,
          ...result.checkIns,
        })
      );

      return dispatch(
        knownForEvents.changed({
          keyWindow,
          keyWindowMeta: { ids: _keys(data.known_for) },
          byId: data.known_for,
        })
      );
    })
    .catch(err => {
      return dispatch(events.fetchFailed({ keyWindow, data: err.message }));
    });
};

export const fetchCheckIns = (user, filters, keyWindow) => dispatch => {
  const isCacheUpdates = keyWindow === keyWindows.CACHE_CHECKINS;

  if (!isCacheUpdates) {
    dispatch(events.fetchStarted({
      keyWindow,
      keyWindowMeta: { ids: [], pagination: {} }
    }));
  }

  return http
    .checkIns(user, filters)
    .then(res => {
      const result = extractResult(res, true);
      const data = normalize(res.data);

      dispatch(
        photoEvents.changed({
          keyWindow,
          byId: data.photo,
        })
      );

      dispatch(
        knownForEvents.changed({
          keyWindow,
          byId: data.known_for,
        })
      );


      if (isCacheUpdates) {
        dispatch(
          events.changed({
            byId: data.destination,
          })
        );
      } else {
        dispatch(
          events.changed({
            keyWindow,
            keyWindowMeta: {
              ids: result.ids,
              pagination: res.data.meta,
            },
            byId: data.destination,
          })
        );
      }

      dispatch(
        events.setRawCachedCheckins({
          keyWindow,
          ...result.checkIns,
        })
      );
    })
    .catch(err => {
      dispatch(events.fetchFailed({ keyWindow, keyWindowMeta: { ids: [] }, data: err.message }));
      throw err;
    });
};

export const fetchCheckInsBySync = (user, filters, keyWindow) => dispatch => {
  const params = filters.page === 1
    ? { keyWindow, keyWindowMeta: { ids: [] } }
    :  { keyWindow };

  dispatch(events.fetchStarted(params));
  return http
    .checkIns(user, filters)
    .then(res => {
      const result = extractResult(res, true);
      const data = normalize(res.data);

      dispatch(
        photoEvents.changed({
          keyWindow,
          byId: data.photo,
        })
      );

      dispatch(
        knownForEvents.changed({
          keyWindow,
          byId: data.known_for,
        })
      );

      dispatch(
        events.changed({
          keyWindow,
          keyWindowMeta: {
            ids: result.ids,
            pagination: res.data.meta,
          },
          byId: data.destination,
          append: filters.page > 1,
        })
      );
    })
    .catch(err => {
      dispatch(events.fetchFailed({ keyWindow, data: err.message }));
      throw err;
    });
}

export const fetchByCheckInProcessId = (checkInProcessId, destinationType, page, keyWindow) => dispatch => {
  const params = page === 1
    ? { keyWindow, keyWindowMeta: { ids: [] } }
    :  { keyWindow };

  dispatch(events.fetchStarted(params));
  return http
    .fetchByCheckInProcessId(checkInProcessId, destinationType, page)
    .then((res) => {
      const ids = _get(res.data, 'data', []).map(item => item.id);
      const data = normalize(res.data);

      dispatch(
        photoEvents.changed({
          keyWindow,
          byId: data.photo,
        })
      );

      dispatch(
        events.changed({
          keyWindow,
          keyWindowMeta: {
            ids,
            pagination: res.data.meta,
          },
          byId: data.destination,
        })
      );
    })
    .catch(err => {
    return dispatch(events.fetchFailed({ keyWindow, data: err.message }));
  });
}

export const voteKnownFor = (slug, knownForItem) => dispatch => {
  dispatch(events.fetchStarted({ keyWindow: 'knownFor' }));
  return http.setKnownFor(slug, knownForItem).catch(err => {
    return dispatch(events.fetchFailed({ keyWindow: 'knownFor', data: err.message }));
  });
};

export const fetchTopCities = (destination, keyWindow) => dispatch => {
  return searchByBrowseDestinations(
    {
      id: destination.id,
      name: destination.attributes.short_name,
      destination_type: destination.attributes.destination_type,
      order: 'Best Match',
      bounding_box: '',
      within_distance: 100,
      cost_list: [],
      per_page: 10,
      show_total: false,
    },
    1,
    keyWindow
  )(dispatch);
};

/**
 * to fetch user suggested places to go Next
 */

export const fetchSuggestedPlaces = (filters, keyWindow = 'suggestedPlaces') => dispatch => {
  dispatch(events.fetchStarted({ keyWindow }));
  return http
    .searchSuggestedDestinations()
    .then(res => {
      const result = extractSuggestedPlaces(res);
      const data = normalize(res);

      dispatch(
        photoEvents.changed({
          keyWindow,
          byId: data.photo,
        })
      );

      dispatch(
        knownForEvents.changed({
          keyWindow,
          byId: data.known_for,
        })
      );

      dispatch(
        events.changed({
          keyWindow,
          keyWindowMeta: { ids: _keys(data.destination), pagination: res.data.meta },
          byId: data.destination,
        })
      );
      dispatch(
        events.setSuggestedDestinations({
          ...result.suggestedPlaces,
        })
      );
    })
    .catch(err => {
      dispatch(events.fetchFailed({ keyWindowMeta: { ids: [] }, data: err.message }));
      throw err;
    });
};
