import _keys from 'lodash/keys';
import http from '../../api/requests/lang';
import normalize from '../../utils/normalizeJson';
import { events } from './reducers';

export const fetchLanguages = keyWindow => dispatch => {
  dispatch(events.fetchStarted({ keyWindow, keyWindowMeta: { ids: [] } }));
  return http
    .fetchLanguages()
    .then(response => {
      const data = normalize(response.data);
      return dispatch(
        events.changed({
          keyWindow,
          keyWindowMeta: { ids: _keys(data.language) },
          byId: data.language,
        })
      );
    })
    .catch(err => {
      return dispatch(
        events.fetchFailed({ keyWindow, keyWindowMeta: { ids: [] }, data: err.message })
      );
    });
};
