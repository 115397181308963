import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import * as SentryReact from '@sentry/react';
import rootReducer from './rootReducer';
import storeManager from './manager';

// createStore : enhancer
export const storeConfig = (initialState = {}) => {
  return configureStore({
    reducer: rootReducer,
    middleware: [...getDefaultMiddleware({ serializableCheck: false })],
    enhancers: [SentryReact.createReduxEnhancer({})],
    preloadedState: initialState,
  });
};

export const Store = storeManager;
