import { createSlice } from '@reduxjs/toolkit';
import { coreReducers, coreState } from '../_core/reducers';

const slice = createSlice({
  name: 'foursquareVenues',
  initialState: { ...coreState },
  reducers: { ...coreReducers },
});

export const { reducer, actions: events } = slice;
