import _isUndefined from 'lodash/isUndefined';
import { getApiClient } from '../';
import NotFoundError from '../../errors/NotFoundError';
import UnauthorizedError from '../../errors/UnauthorizedError';
import oauth from '../requests/oauth';

const isUnauthorized = status => status === 401;
const isApiKeyInvalid = status => status === 403;
const isNotSignInRequest = url => !url.startsWith('/api/v2/oauth');

const renewSessionInterceptor = async error => {
  if (_isUndefined(error.response)) {
    return Promise.reject(error);
  }

  const { status, config } = error.response;
  const originalRequest = config;

  if (isUnauthorized(status) && isNotSignInRequest(config.url) && !originalRequest._retry) {
    originalRequest._retry = true;
    return oauth
      .refreshSession()
      .then(res => {
        console.log('[renewSessionInterceptor] retrying request', res.data); // eslint-disable-line no-console
        getApiClient().setToken(res.data);
        return getApiClient().retryRequest(originalRequest);
      })
      .catch(err => {
        console.log('[renewSessionInterceptor] error on refresh session', err.message); // eslint-disable-line no-console

        if (!getApiClient().isServer()) {
          console.log('[renewSessionInterceptor] deleting fulltoken cookie'); // eslint-disable-line no-console
          getApiClient().delCookie('fullToken');
          window.location.href = '/users/login';
        }

        throw new UnauthorizedError();
      });
  }

  if (isApiKeyInvalid(status) && !originalRequest._retryRenewApiKey) {
    console.log('[renewSessionInterceptor] retrying request using new api key'); // eslint-disable-line no-console
    originalRequest._retryRenewApiKey = true;
    getApiClient().renewApiKey();
    return getApiClient().retryRequest(originalRequest);
  }

  if (status === 404) {
    return Promise.reject(new NotFoundError());
  }

  // eslint-disable-next-line no-console
  console.error(`Error on url=${config.url}, error=${error.message}`, error);
  return Promise.reject(error);
};

export default renewSessionInterceptor;
