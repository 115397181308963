import { getApiClient } from '../..';

/**
 * @param {*} string: {email: <email>, password: <password>}
 */
const fetchElevation = async ({ latitude, longitude }) => {
  const response = await getApiClient().get(`/maps?lat=${latitude}&lon=${longitude}`);
  return response;
};

export default {
  fetchElevation,
};
