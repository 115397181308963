import { getApiClient, api_version } from '../..';

/**
 * @param {*} string
 */
const fetchGetTheres = async destination => {
  const response = await getApiClient().get(
    `${api_version}/destinations/${destination.attributes.slug}/get_there`
  );
  return response;
};

export default { fetchGetTheres };
