import { api_version, getApiClient } from '../..';

/**
 * @param {slug} string
 * @param {destinationType} string (country, city or us-state)
 */
const fetchUserCheckIns = async (slug, destinationType, parentId) => {
  const url = `${api_version}travelers/${slug}/check_ins`;
  const urlParams = {
      destination_type: destinationType,
  };

  if (parentId) {
    urlParams.parent_destination_id = parentId;
  }

  const response = await getApiClient().get(url, { urlParams });
  return response;
};

const createCheckIn = async (destinationId, mark) => {
  const url = `${api_version}destinations/${destinationId}/visits`;
  const data = mark && mark > 0 ? { mark } : { mark: null };
  return await getApiClient().post(url, { data });
};

const removeCheckIn = async destinationId => {
  const url = `${api_version}destinations/${destinationId}/visits`;
  const response = await getApiClient().delete(url);
  return response;
};

const getLeaderboardData = async () => {
  const url = `https://roadgoat-putsreq.herokuapp.com/0aqW5zaEXk8oVhrmUIto`;
  const response = await getApiClient().get(url);
  return response;
};

const maskAchivementsAsRead = async (ids) => {
  const url = `${api_version}check_in_achievements/mark_delivered`;
  const data = { ids: ids.join(',') };
  const response = await getApiClient().post(url, { data });
  return response;
};

export default {
  fetchUserCheckIns,
  createCheckIn,
  removeCheckIn,
  getLeaderboardData,
  maskAchivementsAsRead,
};
