import _camelCase from 'lodash/camelCase';
import _capitalize from 'lodash/capitalize';
import _compact from 'lodash/compact';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import _isUndefined from 'lodash/isUndefined';
import _keys from 'lodash/keys';
import _lowerCase from 'lodash/lowerCase';
import pluralize from 'pluralize';

const processSingularRelationship = (
  store,
  data,
  rel,
  includeRelationships = [],
  parent = '',
  method
) => {
  const newParent = _isEmpty(parent) ? rel : `${parent}.${rel}`;
  const slice = store[pluralize(data.type)];

  if (_isUndefined(slice)) {
    // eslint-disable-next-line no-console
    console.error(
      `slice not found to data.type=${data.type}, rel=${rel}, please verify if you're triggering relationship events on the action responsable to fetch the data, method=${method}`
    );
    return slice;
  }

  const resource = slice.byId[data.id];
  if (_isUndefined(resource)) return resource;

  const relationships = _keys(resource.relationships || {}).map(r => `${newParent}.${r}`);
  const loadMore = !_isEmpty(includeRelationships.filter(r => relationships.includes(r)));
  return loadMore
    ? eagerRelationships(store, resource, includeRelationships, newParent, method)
    : resource;
};

const processPluralRelationship = (
  store,
  data,
  rel,
  includeRelationships = [],
  parent = '',
  method
) => {
  const resources = data.map(value =>
    processSingularRelationship(store, value, rel, includeRelationships, parent, method)
  );
  return resources;
};

export const eagerRelationships = (
  store,
  resource,
  includeRelationships = [],
  parent = '',
  method
) => {
  const relationships = {};
  if (resource) {
    for (const rel in resource.relationships) {
      const relationship = resource.relationships[rel];
      const emptyRelationship = _isUndefined(relationship) || _isEmpty(relationship.data);
      if (!emptyRelationship) {
        const data = relationship.data;
        relationships[rel] = _isArray(data)
          ? processPluralRelationship(store, data, rel, includeRelationships, parent, method)
          : processSingularRelationship(store, data, rel, includeRelationships, parent, method);
      }
    }
  }

  return {
    ...resource,
    relationships,
  };
};

const selectorsBuilder = sliceName => {
  const pluralModelName = _capitalize(_camelCase(sliceName));
  const singularModelName = pluralize.singular(pluralModelName);

  const pluralRelationshipName = _lowerCase(pluralModelName);
  const singularRelationshipName = _lowerCase(singularModelName);

  const getOne = `get${singularModelName}ByKeyWindow`;
  const getMany = `get${pluralModelName}ByKeyWindow`;

  const getByRelationship = `get${singularModelName}ByRelationship`;
  const getManyByRelationships = `get${pluralModelName}ByRelationships`;

  return {
    getKeyWindow: keyWindow => store => {
      const slice = store[sliceName];
      return slice[keyWindow];
    },
    [getOne]: (keyWindow, relationships = [], forceLazyLoad = false) => store => {
      const slice = store[sliceName];
      if (_isUndefined(slice[keyWindow])) {
        return undefined;
      }

      const id = slice[keyWindow].id;

      if (forceLazyLoad) return slice.byId[id];

      return eagerRelationships(store, slice.byId[id], relationships, '', getOne);
    },

    [getMany]: (keyWindow, relationships = [], forceLazyLoad = false, customGetId = (id) => id) => store => {
      const slice = store[sliceName];
      const windowInfo = slice[keyWindow] || {
        isFetching: undefined,
        ids: [],
        pagination: { page: 0, total: 0 },
      };

      const builder = forceLazyLoad
        ? id => slice.byId[customGetId(id)]
        : id => eagerRelationships(store, slice.byId[customGetId(id)], relationships, '', getMany);

      const result = {
        loading: windowInfo.isFetching,
        data: _compact(_get(windowInfo, 'ids', []).map(builder)),
        pagination: windowInfo.pagination,
        isEmptyArray: windowInfo.isEmptyArray,
        raw: windowInfo,
      };

      return result;
    },

    [getManyByRelationships]: (resource, field) => store => {
      const slice = store[sliceName];
      const relationship = resource.relationships[field ? field : pluralRelationshipName];
      const ids = relationship.data.map(rel => rel.id);
      return _compact(ids.map(id => slice.byId[id]));
    },

    [getByRelationship]: (resource, field) => store => {
      const slice = store[sliceName];
      const relationship = resource.relationships[field ? field : singularRelationshipName];
      return relationship && relationship.data ? slice.byId[relationship.data.id] : undefined;
    },
  };
};

export default selectorsBuilder;
