import { createSlice } from '@reduxjs/toolkit';
import _isEmpty from 'lodash/isEmpty';

const initialState = {
  tokenInfo: { slug: null },
  isAuthenticated: false,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    fetchStarted: state => {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    fetchFailed: (state, action) => {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    },
    authenticated: (state, action) => {
      const { successURL, tokenInfo } = action.payload;
      return {
        ...state,
        isFetching: false,
        error: null,
        isAuthenticated: true,
        successURL,
        tokenInfo,
      };
    },
    deauthenticated: state => {
      return {
        ...state,
        isFetching: false,
        error: null,
        isAuthenticated: false,
        tokenInfo: { slug: null },
      };
    },
    tokenInfoChanged: (state, action) => {
      return {
        ...state,
        tokenInfo: action.payload,
        isAuthenticated: !_isEmpty(action.payload.slug),
      };
    },
  },
});

export const { reducer, actions: events } = slice;
