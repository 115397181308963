import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _merge from 'lodash/merge';
import _uniq from 'lodash/uniq';

export const coreState = {
  byId: {},
  keyWindows: [],
};

export const coreReducers = {
  fetchStarted: (state, action) => {
    const { keyWindow = null, keyWindowMeta = {} } = action.payload || {};
    if (_isEmpty(keyWindow)) return state;

    return {
      ...state,
      keyWindows: _uniq([...state.keyWindows, keyWindow]),
      [keyWindow]: {
        ...state[keyWindow],
        isFetching: true,
        cachedData: undefined,
        isEmptyArray: undefined,
        error: null,
        ...keyWindowMeta,
      },
    };
  },

  fetchFailed: (state, action) => {
    const { keyWindow = null, data = 'unknown' } = action.payload || {};
    if (_isEmpty(keyWindow)) return state;

    return {
      ...state,
      keyWindows: _uniq([...state.keyWindows, keyWindow]),
      [keyWindow]: {
        ...state[keyWindow],
        isFetching: false,
        error: data,
      },
    };
  },

  changed: (state, action) => {
    const { append = false, keyWindow, keyWindowMeta = {}, byId = {} } = action.payload;
    const baseState = {
      ...state,
      byId: _merge({}, state.byId, byId),
    };

    if (!keyWindow) return baseState;

    if (append && keyWindowMeta.ids) {
      const currentIds = _get(state, `${keyWindow}.ids`, []);
      keyWindowMeta.ids = currentIds.concat(keyWindowMeta.ids);
    }

    return {
      ...baseState,
      keyWindows: _uniq([...state.keyWindows, keyWindow]),
      [keyWindow]: {
        ...state[keyWindow],
        isFetching: false,
        cachedData: undefined,
        isEmptyArray: keyWindowMeta.ids ? _isEmpty(keyWindowMeta.ids) : undefined,
        error: null,
        ...keyWindowMeta,
      },
    };
  },
};
