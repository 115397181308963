const CACHE_CHECKINS = 'cachedCheckins';

const BROWSE_DESTINATIONS_FILTERS = 'BrowseDestinationsFilters';
const BROWSE_DESTINATIONS_PAGE = 'BrowseDestinationsPage';

const SYNC_CHECK_IN_PROCESSES = 'SyncCheckInProcesses';
const SYNC_CITY_DETAILS_POPUP = 'SyncCityDetailsPopup';
const SYNC_COUNTRY_DETAILS_POPUP = 'SyncCountryDetailsPopup';
const SYNC_STATE_DETAILS_POPUP = 'SyncStateDetailsPopup';
const SYNC_CONTINENT_DETAILS_POPUP = 'SyncContinentDetailsPopup';
const SYNC_REGION_DETAILS_POPUP = 'SyncRegionDetailsPopup';

const PROFILE_SHARE_POPUP = 'profileSharePopup';

export default {
  CACHE_CHECKINS,
  BROWSE_DESTINATIONS_PAGE,
  BROWSE_DESTINATIONS_FILTERS,
  SYNC_CHECK_IN_PROCESSES,
  SYNC_CITY_DETAILS_POPUP,
  SYNC_COUNTRY_DETAILS_POPUP,
  SYNC_STATE_DETAILS_POPUP,
  SYNC_REGION_DETAILS_POPUP,
  SYNC_CONTINENT_DETAILS_POPUP,
  PROFILE_SHARE_POPUP,
};
