const forge = require('node-forge');
const env = require('./environment');

const encrypt = message => {
  const key = env.secrets.secretKey;
  const iv = env.secrets.salt;

  const buffer = forge.util.createBuffer(message, 'utf8');

  const cipher = forge.cipher.createCipher('AES-CBC', key);
  cipher.start({ iv: iv });
  cipher.update(forge.util.createBuffer(buffer));
  cipher.finish();

  return forge.util.encode64(cipher.output.data);
};

const generateSignature = date => {
  const time = Math.floor(date.getTime() / 1000);
  const randomHex = forge.util.bytesToHex(forge.random.getBytesSync(16));
  return encrypt(`${env.secrets.accessKey}|${time}|${randomHex}`);
};

module.exports = {
  generateSignature,
};
