import axios from 'axios';
import jsonpack from 'jsonpack';
import { getCookie, removeCookie, setCookie } from '../../utils/cookie';
import crypt from '../../utils/crypt';
import interceptors from '../interceptors';

export const contentTypeList = {
  form: 'application/x-www-form-urlencoded',
  json: 'application/json',
  image: 'image/png',
};

class httpClient {
  constructor(baseURL, isServer, timeout, context) {
    this.http = this.createInstance(baseURL, timeout);
    this.contentTypeList = contentTypeList;
    this.context = context;
    this.http.interceptors.response.use(res => res, interceptors.renewSessionInterceptor);
    this.http.isServer = isServer;
    this.renewApiKey();
    this.cacheToken = null;
  }

  renewApiKey() {
    this.apiKeyDate = new Date();
    this.apiKey = crypt.generateSignature(this.apiKeyDate);
  }

  getHeaders() {
    const headers = {
      contentType: contentTypeList.json,
      Accept: contentTypeList.json,
    };

    const token = this.getToken();
    if (token) {
      headers['Authorization'] = `Bearer ${token.access_token}`;
    }

    headers['X-API-KEY'] = this.apiKey;
    if (this.context && this.http.isServer) {
      const userAgent = this.context.req.headers['user-agent'];
      if (userAgent) {
        headers['X-User-Agent'] = userAgent;
      }
    } else if (!this.http.isServer) {
      const userAgent = window.navigator.userAgent;
      if (userAgent) {
        headers['X-User-Agent'] = userAgent;
      }
    }

    return headers;
  }

  createInstance(baseURL, timeout) {
    return axios.create({
      baseURL: baseURL,
      timeout: timeout,
      validateStatus: status => {
        return status >= 200 && status < 300;
      },
    });
  }

  isServer() {
    return this.http.isServer;
  }

  retryRequest(request) {
    request.headers = {
      ...request.headers,
      ...this.getHeaders(),
    };

    return this.http(request);
  }

  setToken(token) {
    this.cacheToken = token;
    return setCookie('fullToken', jsonpack.pack(token), this.context, {
      maxAge: 30 * 24 * 60 * 60,
    });
  }

  getToken(context) {
    if (this.cacheToken) {
      return this.cacheToken;
    }

    const token = getCookie('fullToken', context || this.context);
    if (token) {
      return jsonpack.unpack(token);
    }

    return token;
  }

  getAccessToken() {
    const token = this.getToken();
    return token ? token.access_token : '';
  }

  getCookie(key, context) {
    return getCookie(key, context || this.context);
  }

  setCookie(key, value, options = {}) {
    return setCookie(key, value, this.context, options);
  }

  delCookie(key) {
    return removeCookie(key, this.context);
  }

  headerBuilder(customHeaders = {}) {
    const headers = {
      ...this.getHeaders(),
      ...customHeaders,
    };
    return headers;
  }

  safePath(path) {
    return encodeURI(path);
  }

  get(path, { urlParams = {}, headers } = {}) {
    return this.http.get(this.safePath(path), {
      params: urlParams,
      headers: this.headerBuilder(headers),
    });
  }

  post(path, { data, urlParams = {}, headers } = {}) {
    return this.http.post(this.safePath(path), data, {
      params: urlParams,
      headers: this.headerBuilder(headers),
    });
  }

  put(path, { data, urlParams = {}, headers } = {}) {
    return this.http.put(this.safePath(path), data, {
      params: urlParams,
      headers: this.headerBuilder(headers),
    });
  }

  patch(path, { data, urlParams = {}, headers } = {}) {
    return this.http.patch(this.safePath(path), data, {
      params: urlParams,
      headers: this.headerBuilder(headers),
    });
  }

  delete(path, { urlParams = {}, headers } = {}) {
    return this.http.delete(this.safePath(path), {
      params: urlParams,
      headers: this.headerBuilder(headers),
    });
  }
}

export default httpClient;
