import _keys from 'lodash/keys';
import _values from 'lodash/values';
import http from '../../api/requests/checkInProcesses';
import normalize from '../../utils/normalizeJson';
import { events } from './reducers';
import { events as syncEvents } from '../sync/reducers';

export const fetchCheckInProcesses = (filter, keyWindow) => dispatch => {
  dispatch(events.fetchStarted({ keyWindow }));
  return http
    .fetchCheckInProcesses(filter)
    .then(response => {
      const data = normalize(response.data);
      dispatch(
        events.changed({
          keyWindow,
          keyWindowMeta: { ids: _keys(data.check_in_process) },
          byId: data.check_in_process,
        })
      );

      return dispatch(syncEvents.updateByCheckInProcesses({ checkInProcesses: _values(data.check_in_process) }));
    })
    .catch(err => dispatch(events.fetchFailed({ keyWindow, data: err.message })));
};
