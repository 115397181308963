import selectorsBuilder from '../_core/selectors';

const getTravelstatsByWindow = keyWindow => store => {
  const windowInfo = store.travelStats[keyWindow] || { isFetching: false, leaderboard: {} };
  return {
    loading: windowInfo.isFetching,
    leaderboard: windowInfo.leaderboard,
  };
};

export default {
  ...selectorsBuilder('travelStats'),
  getTravelstatsByWindow,
};
