import { getApiClient, api_version } from '../..';

const fetchAuthentications = async () => {
  const response = await getApiClient().get(`${api_version}users/authentications`, {
    urlParams: { permissions: true },
  });
  return response;
};

export default { fetchAuthentications };
