import auth from './auth';
import users from './users';
import authentications from './authentications';
import checkins from './checkins';
import bookmarks from './bookmarks';
import photos from './photos';
import reviews from './reviews';
import destinations from './destinations';
import foursquareVenues from './foursquareVenues';
import languages from './languages';
import knownFor from './knownFor';
import checkInProcesses from './checkInProcesses';
import userKnownFor from './userKnownFor';
import screenShots from './screenShots';
import travelStats from './travelStats';
import mentions from './mentions';
import taggedMentions from './taggedMentions';
import google_maps from './google_maps';
import posts from './posts';
import weather from './weather';
import getTheres from './getTheres';
import seo from './seo';
import facebook from './facebook';
import providerFriends from './providerFriends';
import global from './global';
import sync from './sync';

export default {
  global,
  auth,
  users,
  authentications,
  checkins,
  bookmarks,
  photos,
  reviews,
  destinations,
  foursquareVenues,
  languages,
  knownFor,
  checkInProcesses,
  userKnownFor,
  travelStats,
  mentions,
  taggedMentions,
  google_maps,
  posts,
  weather,
  getTheres,
  seo,
  facebook,
  providerFriends,
  screenShots,
  sync,
};
