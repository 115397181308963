import { getApiClient } from '../..';

/**
 * @param {*} string: {email: <email>, password: <password>}
 */
const fetchWeather = async ({ latitude, longitude }) => {
  const response = await getApiClient().get(`/weather?lat=${latitude}&lon=${longitude}`);
  return response;
};

export default {
  fetchWeather,
};
