import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  map: {
    selectedGeonames: [],
    hoverGeonames: [],
  },
};

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    highlightGeoname: (state, action) => {
      const map = state.map;
      return {
        ...state,
        map: {
          ...map,
          hoverGeonames: [action.payload],
        },
      };
    },
    unhighlightGeoname: state => {
      const map = state.map;
      return {
        ...state,
        map: {
          ...map,
          hoverGeonames: [],
        },
      };
    },
  },
});

export const { reducer, actions: events } = slice;
