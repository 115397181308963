const _ = require('lodash');
const getConfig = require('next/config');
const nextConfig = getConfig.default();

const ENVIRONMENTS = {
  dev: 'development',
  prod: 'production',
  staging: 'staging',
};

const getSecretFromNextConfig = name => {
  return _.get(nextConfig, `publicRuntimeConfig.${name}`);
};

const SECRETS = {
  gaTrackingId: process.env.GA_TRACKING_ID || getSecretFromNextConfig('gaTrackingId'),
  s3Bucket: process.env.S3_BUCKET,
  memcachedUrl: process.env.MEMCACHED_URL,
  accessKey: process.env.API_ACCESS_KEY || getSecretFromNextConfig('apiAcessKey'),
  secretKey: process.env.API_SECRET_KEY || getSecretFromNextConfig('apiSecretKey'),
  salt: process.env.API_SALT || getSecretFromNextConfig('apiSalt'),
  stripePublishableKey:
    process.env.STRIPE_PUBLISHABLE_KEY || getSecretFromNextConfig('stripePublishableKey'),
};

class Environment {
  constructor() {
    this.secrets = SECRETS;
  }

  isServerSide() {
    return typeof window === 'undefined';
  }

  getBaseUrl() {
    return process.env.NEXT_PUBLIC_BASE_URL || getSecretFromNextConfig('baseUrl');
  }

  getPort() {
    return process.env.PORT || 3000;
  }

  getApiUrl() {
    return this.getBaseUrl();
  }

  getWebSocketUrl() {
    return process.env.NEXT_PUBLIC_WEBSOCKET_URL || getSecretFromNextConfig('websocketUrl');
  }

  isNodeDevelopment() {
    return process.env.NODE_ENV !== 'production';
  }

  getEnv() {
    return process.env.ENV || ENVIRONMENTS.dev;
  }

  isProd() {
    return this.getEnv() === ENVIRONMENTS.prod;
  }

  isStaging() {
    return this.getEnv() === ENVIRONMENTS.staging;
  }

  isDev() {
    return this.getEnv() === ENVIRONMENTS.dev;
  }

  isGaEnabled() {
    return !_.isEmpty(this.secrets.gaTrackingId);
  }
}

module.exports = new Environment();
