import _keys from 'lodash/keys';
import httpHelpful from '../../api/requests/helpful';
import http from '../../api/requests/travelers';
import httpUser from '../../api/requests/user';
import normalize from '../../utils/normalizeJson';
import { events as photoEvents } from '../photos/reducers';
import { events as userEvents } from '../users/reducers';
import { events } from './reducers';

// gets current authenticated user
export const fetchUserTips = (user, page, keyWindow) => dispatch => {
  dispatch(events.fetchStarted({ keyWindow, keyWindowMeta: { ids: [] } }));
  return http
    .fetchUserTips(user.attributes.slug, page)
    .then(response => {
      const data = normalize(response.data);
      const pagination = response.data.meta;
      dispatch(
        events.changed({
          keyWindow,
          keyWindowMeta: { ids: _keys(data.visit), pagination },
          byId: data.visit,
        })
      );

      dispatch(
        userEvents.changed({
          keyWindow,
          keyWindowMeta: { ids: _keys(data.user) },
          byId: data.user,
        })
      );

      return dispatch(
        photoEvents.changed({
          keyWindow,
          keyWindowMeta: { ids: _keys(data.photo) },
          byId: data.photo,
        })
      );
    })
    .catch(err => {
      return dispatch(
        events.fetchFailed({ keyWindow, keyWindowMeta: { ids: [] }, data: err.message })
      );
    });
};

export const createTipHelpful = (id, type) => dispatch => {
  dispatch(events.fetchStarted());
  return httpHelpful.createHelpful(id, type).then(() => {
    // dispatch(helpfulCreated());
  });
};

export const deleteTipHelpful = (id, type) => dispatch => {
  dispatch(events.fetchStarted());
  return httpHelpful.deleteHelpful(id, type).then(() => {
    // dispatch(helpfulDeleted());
  });
};

export const deleteTip = (destinationId, visitsId) => dispatch => {
  dispatch(events.fetchStarted());
  return httpUser.deleteTip({ destinationId, visitsId }).then(() => {
    // dispatch(tipDeleted());
  });
};
