import _castArray from 'lodash/castArray';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import { api_version, getApiClient } from '../..';

const searchByBrowseDestinations = async (filters, page = 1) => {
  const url = `${api_version}destinations/search`;
  const urlParams = {
    id: _isEmpty(filters.bounding_box) ? filters.id : '',
    destination_type: 'City',
    bounding_box: filters.bounding_box,
    order: filters.order,
    within_distance: !_isEmpty(filters.id) ? filters.within_distance : '',
    known_for: _castArray(filters.known_for).join(','),
    cost_list: filters.cost_list.join(','),
    skip_user_info: true,
    page,
  };

  urlParams.show_total = _has(filters, 'show_total') ? filters.show_total : true;

  if (filters.skip_visited_destinations) {
    urlParams.visited_destinations = 'skip';
  }

  if (filters.per_page) {
    urlParams.per_page = filters.per_page;
  }

  const response = await getApiClient(url).get(url, { urlParams });
  return response;
};

const fetchAll = async destination_type => {
  const urlParams =
    destination_type === 'State' ? { destination_type, countable: true } : { destination_type };

  const url = `${api_version}destinations/all`;
  return await getApiClient(url).get(url, {
    urlParams,
  });
};

const searchByMyTravelMap = async (filters, page = 1, perPage = 10) => {
  if (['Country', 'State'].includes(filters.destination_type)) {
    return fetchAll(filters.destination_type);
  }

  const url = `${api_version}destinations/search`;
  const urlParams = {
    ...filters,
    visited_destinations: 'skip',
    page,
    per_page: perPage,
    skip_user_info: true,
  };

  const isDefaultRequest = page === 1 && _isEmpty(urlParams.id);
  urlParams.show_total = !isDefaultRequest;
  const validFields = [
    'id',
    'destination_type',
    'order',
    'visited_destinations',
    'bounding_box',
    'page',
    'per_page',
    'show_total',
    'skip_user_info',
  ];

  return await getApiClient(url).get(url, { urlParams: _pick(urlParams, validFields) });
};

const autoComplete = async (query, skipUserInfo = true) => {
  const url = `${api_version}destinations/auto_complete`;
  const response = await getApiClient().get(url, {
    urlParams: {
      q: query,
      skip_user_info: skipUserInfo,
    },
  });
  return response;
};

const checkIns = async (user, filters) => {
  const url = `${api_version}travelers/${user.attributes.slug}/check_ins`;
  const result = await getApiClient().get(url, {
    urlParams: {
      destination_type: 'City,State,Country',
      destination_size: 'micro',
      per_page: 100000,
      meta: false,
      ...filters,
    },
  });
  return result;
};

const fetchByCheckInProcessId = async (checkInProcessId, destinationType, page = 1) => {
  const url = `${api_version}check_in_processes/${checkInProcessId}/check_ins`;
  const result = await getApiClient().get(url, { urlParams: { destination_type: destinationType, page }});
  return result;
}

const fetchDestination = async slug => {
  return await getApiClient().get(`${api_version}destinations/${slug}`);
};

const setKnownFor = async (destinationId, knownFor) => {
  const auxKnownFor = knownFor.data.length > 0 ? knownFor : 'none';
  const urlParams = {
    known_for_ids: auxKnownFor.data,
  };
  return await getApiClient().patch(`${api_version}destinations/${destinationId}/visits`, {
    urlParams,
  });
};

const searchSuggestedDestinations = async (filters, page = 1) => {
  const url = `${api_version}destinations/search`;
  const urlParamsCountry = {
    destination_type: 'Country',
    bounding_box: null,
    sorted_by: 'Best Match',
    distance: null,
    page: page,
    per_page: 4,
    visited_destinations: 'skip',
  };
  const urlParamsCity = {
    destination_type: 'City',
    bounding_box: null,
    sorted_by: 'Best Match',
    distance: null,
    page: page,
    per_page: 12,
    visited_destinations: 'skip',
  };
  let data = {};

  try {
    const { data: responseCountry } = await getApiClient(url).get(url, {
      urlParams: urlParamsCountry,
    });
    const { data: responseCity } = await getApiClient(url).get(url, { urlParams: urlParamsCity });
    let total = 12 - responseCountry.data.length;

    data.data = [...responseCountry.data, ...responseCity.data.slice(0, total)];
    data.included = [...responseCountry.included, ...responseCity.included];
    data.meta = { page: 1, per_page: responseCountry.data.length + total };

    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error', error);
  }
};

export default {
  fetchDestination,
  searchByMyTravelMap,
  searchByBrowseDestinations,
  searchSuggestedDestinations,
  fetchByCheckInProcessId,
  checkIns,
  setKnownFor,
  autoComplete,
};
