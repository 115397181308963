import _get from 'lodash/get';
import http from '../../api/requests/poi';
import normalize from '../../utils/normalizeJson';
import { events as categoryEvents } from '../foursquareVenueCategories/reducers';
import { events } from './reducers';

export const queryFoursquareDestinations = (destination, query, keyWindow) => dispatch => {
  const destinationSlug = destination.attributes.slug;

  dispatch(events.fetchStarted({ keyWindow, keyWindowMeta: { destinationSlug, query, ids: [] } }));
  return http
    .queryFoursquareDestinations(destinationSlug, query)
    .then(response => {
      const ids = _get(response.data, 'data', []).map(venue => venue.id);
      const data = normalize(response.data);

      dispatch(
        events.changed({
          keyWindow,
          keyWindowMeta: { ids },
          byId: data.foursquare_venue,
        })
      );

      return dispatch(categoryEvents.changed({ byId: data.foursquare_venue_category }));
    })
    .catch(err => {
      return dispatch(events.fetchFailed({ keyWindow, data: err.message }));
    });
};
