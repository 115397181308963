import _isUndefined from 'lodash/isUndefined';
import { api_version, getApiClient } from '../..';

/**
 * @param {*} string
 */
const fetchUser = async slug => {
  if (_isUndefined(slug)) {
    throw new TypeError('slug is not defined');
  }

  const response = await getApiClient().get(`${api_version}travelers/${slug}`);
  return response;
};

/**
 * @param {*} string
 */
const fetchUserTips = async (slug, page) => {
  const response = await getApiClient().get(`${api_version}travelers/${slug}/visits`, {
    urlParams: { page },
  });
  return response;
};

/**
 * @param {*} null
 */
const fetchUserCheckIns = async slug => {
  const response = getApiClient().get(`${api_version}travelers/${slug}/check_ins`);
  return response;
};

const fetchUserFollowers = async (slug, page) => {
  const response = getApiClient().get(`${api_version}travelers/${slug}/followers`, {
    urlParams: { page },
  });
  return response;
};

const fetchUserFollowings = async (slug, page) => {
  const response = getApiClient().get(`${api_version}travelers/${slug}/followings`, {
    urlParams: { page },
  });
  return response;
};

const queryVisitors = async (provider, query, page) => {
  const urlByRoadgoat = `${api_version}travelers`;
  const urlByProvider = `${api_version}users/friends`;

  const url = provider === 'roadgoat' ? urlByRoadgoat : urlByProvider;
  const response = await getApiClient().get(url, {
    urlParams: {
      name: query,
      provider,
      page,
    },
  });
  return response;
};

const fetchUserScreenShots = async user => {
  const response = await getApiClient().get(
    `${api_version}travelers/${user.attributes.slug}/screen_shots`
  );
  return response;
};

const generateUserScreenShots = async (user, destinationType) => {
  const response = await getApiClient().post(
    `${api_version}travelers/${user.attributes.slug}/screen_shots`,
    {
      data: {
        destination_type: destinationType,
      },
    }
  );
  return response;
};

export default {
  fetchUser,
  fetchUserTips,
  fetchUserCheckIns,
  fetchUserFollowers,
  fetchUserFollowings,
  fetchUserScreenShots,
  generateUserScreenShots,
  queryVisitors,
};
