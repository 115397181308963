import { createSlice } from '@reduxjs/toolkit';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';

const isProviderRunning = (status = 'unknown') => ['processing', 'scheduled'].includes(status);

const initialState = {
  wsConnected: false,
};

const providerInitialState = {
  authenticated: false,
  running: true,
};

const slice = createSlice({
  name: 'sync',
  initialState,
  reducers: {
    info: (state, action) => {
      const { wsConnected = false } = action.payload;
      return { ...state, wsConnected };
    },

    authentications: (state, action) => {
      const { authentications } = action.payload;
      const providerChanges = {};

      authentications.forEach(authentication => {
        const providerState = state[authentication.attributes.provider] || providerInitialState;
        providerChanges[authentication.attributes.provider] = {
          ...providerState,
          provider: authentication.attributes.provider,
          authenticated: authentication.attributes.connection_status === 'connected',
        };
      });

      return { ...state, ...providerChanges };
    },

    // ws messages
    update: (state, action) => {
      const { provider, status, connection_status } = action.payload;
      const providerState = state[provider] || providerInitialState;
      const authenticated = connection_status
        ? connection_status === 'connected'
        : providerState.authenticated;

      return {
        ...state,
        [provider]: {
          ...providerState,
          provider,
          lastMessage: action.payload,
          authenticated,
          lastUpdatedAt: moment(),
          running: isProviderRunning(status),
        },
      };
    },

    updateByCheckInProcesses: (state, action) => {
      const { checkInProcesses } = action.payload;
      const changes = {};
      const limitDate = moment().subtract(30, 'minutes');

      checkInProcesses.forEach(checkInProcess => {
        const isCompleted = ['completed', 'error'].includes(checkInProcess.attributes.status);
        const checkInProcessUpdatedAt = moment(checkInProcess.attributes.updated_at);

        const providerState = state[checkInProcess.attributes.provider] || providerInitialState;

        // check if check in process was updated until 30min ago
        if (isCompleted && checkInProcessUpdatedAt.isBefore(limitDate)) {
          changes[checkInProcess.attributes.provider] = {
            ...providerState,
            running: false,
            lastUpdatedAt: checkInProcessUpdatedAt,
            lastMessage: null,
          };

          return;
        }

        // check if the curent state is already up-to-date
        if (
          !_isEmpty(providerState.lastUpdatedAt) &&
          checkInProcessUpdatedAt.isBefore(providerState.lastUpdatedAt)
        ) {
          return;
        }

        changes[checkInProcess.attributes.provider] = {
          ...providerState,
          provider: checkInProcess.attributes.provider,
          lastUpdatedAt: checkInProcessUpdatedAt,
          lastMessage: {
            provider: checkInProcess.attributes.provider,
            status: checkInProcess.attributes.status || 'unknown',
            message: checkInProcess.attributes.processing_message,
            check_in_process_id: checkInProcess.id,
            source: checkInProcess.attributes.source,
          },
          running: isProviderRunning(checkInProcess.attributes.status),
        };
      });

      return { ...state, ...changes };
    },
  },
});

export const { reducer, actions: events } = slice;
