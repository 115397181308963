import _get from 'lodash/get';
import _keys from 'lodash/keys';
import httpHelpful from '../../api/requests/helpful';
import http from '../../api/requests/photos';
import normalize from '../../utils/normalizeJson';
import { events as destinationEvents } from '../destinations/reducers';
import { events as userEvents } from '../users/reducers';
import { events } from './reducers';

// get user photos
export const fetchUserPhotos = (user, keyWindow) => dispatch => {
  dispatch(events.fetchStarted({ keyWindow, keyWindowMeta: { ids: [] } }));
  return http
    .fetchUserPhotos(user.id)
    .then(response => {
      const ids = _get(response.data, 'data', []).map(photo => photo.id);
      const data = normalize(response.data);
      dispatch(
        events.changed({
          keyWindow,
          keyWindowMeta: { ids },
          byId: data.photo,
        })
      );
      return dispatch(
        userEvents.changed({
          keyWindow,
          keyWindowMeta: { ids: _keys(data.user) },
          byId: data.user,
        })
      );
    })
    .catch(err => {
      return dispatch(
        events.fetchFailed({ keyWindow, keyWindowMeta: { ids: [] }, data: err.message })
      );
    });
};

export const fetchPhotos = (id, page, keyWindow) => dispatch => {
  const keyWindowMeta = page == 1 ? { ids: [], pagination: undefined } : {};
  dispatch(events.fetchStarted({ keyWindow, keyWindowMeta }));
  return http
    .fetchPhotos(id, page)
    .then(response => {
      const ids = _get(response.data, 'data', []).map(photo => photo.id);
      const data = normalize(response.data);

      dispatch(
        events.changed({
          append: page > 1,
          keyWindow,
          keyWindowMeta: { ids, pagination: response.data.meta },
          byId: data.photo,
        })
      );
      return dispatch(
        userEvents.changed({
          keyWindow,
          keyWindowMeta: { ids: _keys(data.user) },
          byId: data.user,
        })
      );
    })
    .catch(err => {
      return dispatch(
        events.fetchFailed({ keyWindow, keyWindowMeta: { ids: [] }, data: err.message })
      );
    });
};

// get user photos
export const fetchPhoto = (id, keyWindow) => dispatch => {
  dispatch(events.fetchStarted({ keyWindow, keyWindowMeta: { ids: [] } }));
  return http
    .fetchPhoto(id)
    .then(response => {
      const data = normalize(response.data);
      dispatch(
        events.changed({
          keyWindow,
          keyWindowMeta: { id: _keys(data.photo)[0] },
          byId: data.photo,
        })
      );
      dispatch(
        userEvents.changed({
          keyWindow,
          keyWindowMeta: { id: _keys(data.user)[0] },
          byId: data.user,
        })
      );
      return dispatch(
        destinationEvents.changed({
          keyWindow,
          keyWindowMeta: { id: _keys(data.destination)[0] },
          byId: data.destination,
        })
      );
    })
    .catch(err => {
      return dispatch(
        events.fetchFailed({ keyWindow, keyWindowMeta: { ids: [] }, data: err.message })
      );
    });
};

export const createPhotoHelpful = (id, type) => dispatch => {
  dispatch(events.fetchStarted());
  return httpHelpful.createHelpful(id, type).then(() => {});
};

export const deletePhotoHelpful = (id, type) => dispatch => {
  dispatch(events.fetchStarted());
  return httpHelpful.deleteHelpful(id, type).then(() => {});
};
