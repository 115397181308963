import _keys from 'lodash/keys';
import _values from 'lodash/values';
import http from '../../api/requests/authentications';
import normalize from '../../utils/normalizeJson';
import { events } from './reducers';
import { events as syncEvents } from '../sync/reducers';

const keyWindow = 'global';

export const fetchAuthentications = () => dispatch => {
  dispatch(events.fetchStarted({ keyWindow }));
  return http
    .fetchAuthentications()
    .then(response => {
      const data = normalize(response.data);
      dispatch(
        events.changed({
          keyWindow,
          keyWindowMeta: { ids: _keys(data.authentication) },
          byId: data.authentication,
        })
      );

      return dispatch(
        syncEvents.authentications({ authentications: _values(data.authentication) })
      );
    })
    .catch(err => {
      return dispatch(
        events.fetchFailed({ keyWindow, keyWindowMeta: { ids: [] }, data: err.message })
      );
    });
};
