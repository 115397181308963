import decode from 'jwt-decode';
import { getApiClient } from '../api';

export const getAuthTokenInfo = context => {
  const token = getApiClient().getToken(context);
  return token ? decode(token.access_token).user : { slug: null };
};

export const getUserInfoByToken = (jwtToken, value) => {
  const info = decode(jwtToken);

  if (value === 'all') {
    return info;
  } else if (value === 'user') {
    return info.user;
  } else {
    return null;
  }
};
