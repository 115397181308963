import ejs from 'ejs';
import _isEmpty from 'lodash/isEmpty';
import _trim from 'lodash/trim';
import probe from 'probe-image-size';
import http from '../../api/requests/seo';
import { events } from './reducers';

const templateParser = (params, field, originalString) => {
  try {
    if (_isEmpty(originalString)) return '';

    return _trim(ejs.render(originalString, params));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Error on parse mea tag, field=${field}, content=${originalString}`, e);
    return '';
  }
};

const parseMetaTags = (data, params) => {
  const parsedParams = {};
  (data.params || []).forEach(param => {
    parsedParams[param] = params[param];
  });

  const title = templateParser(parsedParams, 'title', data.title);
  const og_meta_title = templateParser(parsedParams, 'og_meta_title', data.og_meta_title);

  const metadata_description = templateParser(
    parsedParams,
    'metadata_description',
    data.metadata_description
  );
  const metadata_canonical_url = templateParser(
    parsedParams,
    'og_meta_image',
    data.metadata_canonical_url
  );

  const og_meta_image = templateParser(parsedParams, 'og_meta_image', data.og_meta_image);
  const og_meta_description = templateParser(
    parsedParams,
    'og_meta_description',
    data.og_meta_description
  );
  const og_type = templateParser(parsedParams, 'og_type', data.og_type);

  const json_ld = templateParser(parsedParams, 'json_ld', data.json_ld);

  const baseMeta = {
    params: data.params,
    title,
    metadata_description,
    metadata_canonical_url,
    og_meta_title,
    og_meta_description,
    og_type,
    og_meta_image: 'https://cdn.roadgoat.com/images/shared/social/roadgoat-share.jpg',
    og_meta_image_width: 110,
    og_meta_image_height: 110,
    json_ld,
  };

  if (_isEmpty(og_meta_image)) {
    return baseMeta;
  }

  return probe(og_meta_image, { timeout: 3000 })
    .then(result => {
      const og_meta_image_width = result.width;
      const og_meta_image_height = result.height;
      return Object.assign(baseMeta, {
        og_meta_image,
        og_meta_image_width,
        og_meta_image_height,
      });
    })
    .catch(err => {
      // eslint-disable-next-line no-console
      console.error(`ERROR on get image dimensions org_meta_image=${og_meta_image}`, err);
      return baseMeta;
    });
};

export const getObject = (key, currentPath, params, customJsonLdParams) => dispatch => {
  dispatch(events.fetchStarted({ key, currentPath }));
  return http
    .fetchObject(key)
    .then(({ data }) => parseMetaTags(data, params, customJsonLdParams))
    .then(data => {
      return dispatch(events.changed({ data, key, params }));
    })
    .catch(err => {
      // eslint-disable-next-line no-console
      console.error('Airtable getObject Error', err);
    });
};
