import { getApiClient, api_version } from '../..';

const queryFoursquareDestinations = async (destinationSlug, query) => {
  return await getApiClient().get(`${api_version}destinations/${destinationSlug}/foursquare_venues`, {
    urlParams: {
      q: query,
    },
  });
};

export default { queryFoursquareDestinations };
