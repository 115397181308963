import { getApiClient, api_version } from '../..';

const fetchPhotos = async (filterBy, page = 1) => {
  const response = getApiClient().get(`${api_version}photos?filter_by=${filterBy}&page=${page}`);
  return response;
};
const fetchPhoto = async id => {
  const response = getApiClient().get(`${api_version}photos/${id}`);
  return response;
};

const fetchUserPhotos = async id => fetchPhotos(`User-${id}`);

export default {
  fetchUserPhotos,
  fetchPhotos,
  fetchPhoto,
};
