import _get from 'lodash/get';
import _isUndefined from 'lodash/isUndefined';
import selectorsBuilder, { eagerRelationships } from '../_core/selectors';

const getLoggedUser = () => store => {
  const id = _get(store.auth, 'tokenInfo.id');
  if (_isUndefined(id)) return undefined;
  const user = store.users.byId[id];
  return user ? eagerRelationships(store, user, [], '', 'getLoggedUser') : undefined;
};

const isLoggedUser = user => store => {
  const id = _get(store.auth, 'tokenInfo.id');
  return user && `${id}` === user.id;
};

const baseSelectors = selectorsBuilder('users');

export default {
  ...baseSelectors,
  getLoggedUser,
  isLoggedUser,
};
