import { getApiClient } from '../..';

/**
 * @param {*} string: {email: <email>, password: <password>}
 */
const fetchPosts = async ({ limit }) => {
  const response = await getApiClient().get(
    `https://www.roadgoat.com/blog/ghost/api/v3/content/posts/?key=fb9d51ec4108b11d88c494069a&include=tags,authors&filter=featured:true${
      limit ? `&limit=${limit}` : ''
    }`
  );
  return response;
};

export default {
  fetchPosts,
};
