import _keys from 'lodash/keys';
import http from '../../api/requests/knownFor';
import normalize from '../../utils/normalizeJson';
import { events } from './reducers';

export const fetchKnownFor = keyWindow => dispatch => {
  dispatch(events.fetchStarted({ keyWindow }));
  return http
    .fetchKnownFor()
    .then(response => {
      const data = normalize(response.data);
      return dispatch(
        events.changed({
          keyWindow,
          keyWindowMeta: { ids: _keys(data.known_for) },
          byId: data.known_for,
        })
      );
    })
    .catch(err => dispatch(events.fetchFailed({ keyWindow, data: err.message })));
};
