import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { coreReducers, coreState } from '../_core/reducers';

const recomputeCachedCheckinIds = (keyWindow, state, destination_type) => {
  const types = ['City', 'State', 'Country'];
  const typesToKeepIds = types.filter(type => type !== destination_type);

  const allIds = _.flatten(typesToKeepIds.map(type => _.get(state, `${keyWindow}.${type}`, [])));

  return allIds;
};

const idAsString = (id) => String(id);

const slice = createSlice({
  name: 'destinations',
  initialState: { ...coreState },
  reducers: {
    ...coreReducers,
    addCachedCheckins: (state, action) => {
      const { destination } = action.payload;
      const { destination_type, countable } = destination.attributes;
      const keyWindow = 'cachedCheckins';
      const currentIds = _.get(state[keyWindow], destination_type, []);

      const ids = currentIds.concat([{ id: destination.id, countable }]);
      const allIds = recomputeCachedCheckinIds(keyWindow, state, destination_type);


      return {
        ...state,
        keyWindows: _.uniq([...state.keyWindows, keyWindow]),
        [keyWindow]: {
          ...state[keyWindow],
          [destination_type]: _.uniqBy(ids, item => item.id),
          ids: _.uniqBy(allIds.concat(ids), (item) => item.id),
        },
      };
    },
    removeCachedCheckins: (state, action) => {
      const { destination } = action.payload;
      const destination_type = destination.attributes.destination_type;
      const keyWindow = 'cachedCheckins';
      const currentIds = _.get(state[keyWindow], destination_type, []);

      const allIds = recomputeCachedCheckinIds(keyWindow, state, destination_type);
      const ids = currentIds.filter(({ id }) => id !== destination.id);

      return {
        ...state,
        keyWindows: _.uniq([...state.keyWindows, keyWindow]),
        [keyWindow]: {
          ...state[keyWindow],
          [destination_type]: ids,
          ids: allIds.concat(ids),
        },
      };
    },
    setAllCachedCheckins: (state, action) => {
      const keyWindow = 'cachedCheckins';
      const { City = [], Country = [], State = [] } = action.payload;

      const cityIds = City.map(i => ({ ...i, id: idAsString(i.id) }));
      const countryIds = Country.map(i => ({ ...i, id: idAsString(i.id) }));
      const stateIds = State.map(i => ({ ...i, id: idAsString(i.id) }));
      const allIds = cityIds.concat(countryIds).concat(stateIds);

      return {
        ...state,
        [keyWindow]: {
          ...state[keyWindow],
          City: _.uniqBy(cityIds, (item) => item.id),
          State: _.uniqBy(stateIds, (item) => item.id),
          Country: _.uniqBy(countryIds, (item) => item.id),
          ids: _.uniqBy(allIds, (item) => item.id),
        },
      };
    },
    setRawCachedCheckins: (state, action) => {
      const keyWindow = 'cachedCheckins';
      const { City = [], Country = [], State = [] } = action.payload;

      const currentAllIds = _.get(state[keyWindow], 'ids', []);
      const currentCityIds = _.get(state[keyWindow], 'City', []);
      const currentStateIds = _.get(state[keyWindow], 'State', []);
      const currentCountryIds = _.get(state[keyWindow], 'Country', []);

      const allIds = City.concat(Country).concat(State);

      return {
        ...state,
        keyWindows: _.uniq([...state.keyWindows, keyWindow]),
        [keyWindow]: {
          ...state[keyWindow],
          City: _.uniqBy(currentCityIds.concat(City), (item) => item.id),
          State: _.uniqBy(currentStateIds.concat(State), (item) => item.id),
          Country: _.uniqBy(currentCountryIds.concat(Country), (item) => item.id),
          ids: _.uniqBy(currentAllIds.concat(allIds), (item) => item.id),
        },
      };
    },
    clearOrderDestinations: (state, action) => {
      const { originalIds, keyWindow } = action.payload;
      return {
        ...state,
        [keyWindow]: {
          ...state[keyWindow],
          mostVisitedIds: originalIds,
          ids: originalIds,
        },
      };
    },
    orderDestinations: (state, action) => {
      const { order, keyWindow } = action.payload;
      const mostVisitedIds = state[keyWindow].mostVisitedIds || state[keyWindow].ids;
      const alphabeticalIds = state[keyWindow].alphabeticalIds;

      if (order === 'Most Visited') {
        return {
          ...state,
          [keyWindow]: {
            ...state[keyWindow],
            mostVisitedIds,
            ids: mostVisitedIds,
            alphabeticalIds,
          },
        };
      } else {
        const sortByAlphabetic = () => {
          const destinations = state[keyWindow].ids.map(id => state.byId[id]);
          return destinations
            .sort((destinationA, destinationB) =>
              destinationA.attributes.name.localeCompare(destinationB.attributes.name)
            )
            .map(destination => destination.id);
        };

        const ids = sortByAlphabetic();

        return {
          ...state,
          [keyWindow]: {
            ...state[keyWindow],
            mostVisitedIds,
            alphabeticalIds: ids,
            ids,
          },
        };
      }
    },
    setSuggestedDestinations: (state, action) => {
      const keyWindow = 'suggestedPlaces';
      const { City = [], Country = [] } = action.payload;

      const currentAllIds = _.get(state[keyWindow], 'ids', []);
      const currentCityIds = _.get(state[keyWindow], 'City', []);
      const currentCountryIds = _.get(state[keyWindow], 'Country', []);

      const allIds = City.concat(Country);

      return {
        ...state,
        keyWindows: _.uniq([...state.keyWindows, keyWindow]),
        [keyWindow]: {
          ...state[keyWindow],
          City: _.uniq(currentCityIds.concat(City)),
          Country: _.uniq(currentCountryIds.concat(Country)),
          ids: _.uniq(currentAllIds.concat(allIds)),
        },
      };
    },
  },
});

export const { reducer, actions: events } = slice;
