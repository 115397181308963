import { getApiClient, api_version } from '../..';

const createHelpful = async (id, type) => {
  const response = await getApiClient().post(`${api_version}helpful/${type}/${id}`);
  return response;
};

const deleteHelpful = async (id, type) => {
  const response = await getApiClient().delete(`${api_version}helpful/${type}/${id}`);
  return response;
};

export default {
  createHelpful,
  deleteHelpful,
};
