import { getApiClient, api_version } from '../..';

/**
 * @param {*} string
 */
const fetchLanguages = async () => {
  const response = await getApiClient().get(`${api_version}languages`);
  return response;
};

export default { fetchLanguages };
