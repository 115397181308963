import _isEmpty from 'lodash/isEmpty';
import { api_version, getApiClient } from '../..';

/**
 * @param {*} null
 */
const fetchCurrentUser = async () => {
  const response = await getApiClient().get(`${api_version}me`);
  return response;
};

/**
 * @param {*} data {email: <email>, name: <name>, password: <password>}
 */
const createUser = async ({ email, name, password }) => {
  const response = await getApiClient().post(`${api_version}users.json`, {
    data: {
      user: {
        email,
        name,
        password,
      },
    },
  });
  return response;
};

/**
 * @param {*} binary
 */
const createUserAvatar = async file => {
  const reader = new FileReader();
  let blob;
  blob = await fetch(file).then(r => r.blob());
  reader.onloadend = () => {
    const response = getApiClient().put(`${api_version}me`, {
      data: {
        avatar: reader.result,
      },
    });
    return response;
  };
  await reader.readAsArrayBuffer(blob);
};

/**
 * @param {*} binary
 */
const editUserAvatar = async ({ crop_x, crops_y, crop_w, crop_h }) => {
  const response = await getApiClient().put(`${api_version}me`, {
    data: {
      user: {
        avatar_crop_x: crop_x,
        avatar_crop_y: crops_y,
        avatar_crop_w: crop_w,
        avatar_crop_h: crop_h,
      },
    },
  });
  return response;
};
/**
 * @param {*} binary
 */
const deleteUser = async token => {
  const response = await getApiClient().delete(
    `${api_version}me?confirmation_token=${token || ''}`
  );
  if (!_isEmpty(response.data.confirmation_token)) {
    return deleteUser(response.data.confirmation_token);
  }
  return response;
};
/**
 * @param {*} binary
 */
const deleteUserAvatar = async () => {
  const response = await getApiClient().put(`${api_version}me`, {
    data: {
      user: {
        remove_avatar: true,
      },
    },
  });
  return response;
};

/**
 * @param {*} string user_id, type
 */
const followUser = async (id, type) => {
  if (type === 'follow') {
    const response = await getApiClient().post(`${api_version}travelers/${id}/follow`);
    return response;
  } else {
    const response = await getApiClient().post(`${api_version}travelers/${id}/unfollow`);
    return response;
  }
};

/**
 * @param {*} object
 */
const updateUserProfile = async data => {
  const response = await getApiClient().put(`${api_version}me`, {
    data,
  });
  return response;
};

/**
 * @param {*}string
 */
const requestPassword = async ({ email }) => {
  const response = await getApiClient().post(`${api_version}users/password`, {
    data: {
      user: {
        email,
      },
    },
  });
  return response;
};

/**
 * @param {*} string
 */
const updatePassword = async ({ password, key }) => {
  const response = await getApiClient().put(`${api_version}users/password`, {
    data: {
      user: {
        password,
        reset_password_token: key,
      },
    },
  });
  return response;
};

/**
 * @param {*}
 */
const deleteTip = async ({ destinationId, visitsId }) => {
  const response = await getApiClient().delete(
    `${api_version}destinations/${destinationId}/visits/${visitsId}`,
    {}
  );
  return response;
};

const inviteFriends = async ({ emails, text, name }) => {
  const response = await getApiClient().post(`${api_version}communications/invites`, {
    data: {
      to: emails,
      content: text,
      from: name,
    },
  });
  return response;
};

const contactUs = async ({ from, name, subject, content }) => {
  const response = await getApiClient().post(`${api_version}communications/contact_us`, {
    data: {
      email: from,
      name: name,
      subject: subject,
      content: content,
    },
  });
  return response;
};

const fetchFriendsByDestination = async (destination, type, page) => {
  // const url = `${api_version}destinations/${destination.attributes.slug}/my_friends`;
  const url = `${api_version}destinations/${destination}/my_friends?name=`;
  const response = getApiClient().get(url, {
    urlParams: { type, page },
  });
  return response;
};

const syncProvider = async (provider, params = {}) => {
  const url = `${api_version}users/authentications/${provider}/sync`;
  const response = getApiClient().post(url, {
    data: params,
  });
  return response;
};

const disconnectProvider = async provider => {
  const url = `${api_version}users/authentications/${provider}`;
  const response = getApiClient().delete(url);
  return response;
};

const businessSignUp = async values => {
  const url = `${api_version}stripe/setup`;
  const response = await getApiClient().post(url, {
    data: values,
  });
  return response;
};

const verifyEmail = async token => {
  return await getApiClient().get(`${api_version}users/confirmation?confirmation_token=${token}`);
};

export default {
  createUser,
  createUserAvatar,
  editUserAvatar,
  deleteUser,
  deleteUserAvatar,
  fetchCurrentUser,
  updateUserProfile,
  requestPassword,
  updatePassword,
  followUser,
  deleteTip,
  inviteFriends,
  contactUs,
  fetchFriendsByDestination,
  syncProvider,
  disconnectProvider,
  businessSignUp,
  verifyEmail,
};
