import { getApiClient, api_version } from '../..';

const fetchCheckInProcesses = async (filter = {}) => {
  const response = await getApiClient().get(`${api_version}check_in_processes`, {
    urlParams: filter,
  });
  return response;
};

export default { fetchCheckInProcesses };
