import { api_version, getApiClient } from '../..';

/**
 * @param {logged} boolean
 */
const fetchLeaderboard = async (logged, siteWide) => {
  let auxParam;
  if (logged) {
    auxParam = `?filtered=${siteWide}`;
  } else {
    auxParam = '';
  }
  const response = await getApiClient().get(`${api_version}leaderboards` + auxParam);
  return response;
  // const url = `https://roadgoat-putsreq.herokuapp.com/0aqW5zaEXk8oVhrmUIto`;
  // const response = await getApiClient().get(url);
  // return response;
};
export default {
  fetchLeaderboard,
};
