import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
  isFetching: false,
  error: null,
  currentPath: null,
};

const slice = createSlice({
  name: 'seo',
  initialState,
  reducers: {
    fetchStarted: (state, action) => {
      const { key, currentPath } = action.payload;
      return {
        ...state,
        isFetching: true,
        error: null,
        currentPath,
        key,
      };
    },
    fetchFailed: (state, action) => {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    },
    changed: (state, action) => {
      const { data, key, params } = action.payload;
      return {
        ...state,
        data,
        key,
        params,
        isFetching: false,
      };
    },
  },
});

export const { reducer, actions: events } = slice;
