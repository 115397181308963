import { combineReducers } from '@reduxjs/toolkit';
import { reducer as authReducer } from './auth/reducers';
import { reducer as userReducer } from './users/reducers';
import { reducer as authenticationReducer } from './authentications/reducers';
import { reducer as checkinReducer } from './checkins/reducers';
import { reducer as bookmarkReducer } from './bookmarks/reducers';
import { reducer as photoReducer } from './photos/reducers';
import { reducer as reviewReducer } from './reviews/reducers';
import { reducer as destinationReducer } from './destinations/reducers';
import { reducer as covidCaseReducer } from './covidCases/reducers';
import { reducer as foursquareVenueReducer } from './foursquareVenues/reducers';
import { reducer as foursquareVenueCategoryReducer } from './foursquareVenueCategories/reducers';
import { reducer as langReducer } from './languages/reducers';
import { reducer as knownForReducer } from './knownFor/reducers';
import { reducer as screenShotsReducer } from './screenShots/reducers';
import { reducer as checkInProcessReducer } from './checkInProcesses/reducers';
import { reducer as userKnownForReducer } from './userKnownFor/reducers';
import { reducer as travelStatsReducer } from './travelStats/reducers';
import { reducer as mentionReducer } from './mentions/reducers';
import { reducer as seoReducer } from './seo/reducers';
import { reducer as taggedMentionReducer } from './taggedMentions/reducers';
import { reducer as googleMapsReducer } from './google_maps/reducers';
import { reducer as postsReducer } from './posts/reducers';
import { reducer as weatherReducer } from './weather/reducers';
import { reducer as getThereReducer } from './getTheres/reducers';
import { reducer as facebookReducer } from './facebook/reducers';
import { reducer as providerFriendEvents } from './providerFriends/reducers';
import { reducer as globalReducer } from './global/reducers';
import { reducer as syncReducer } from './sync/reducers';

const reducers = combineReducers({
  global: globalReducer,
  auth: authReducer,
  users: userReducer,
  authentications: authenticationReducer,
  photos: photoReducer,
  checkins: checkinReducer,
  bookmarks: bookmarkReducer,
  reviews: reviewReducer,
  destinations: destinationReducer,
  covidCases: covidCaseReducer,
  foursquareVenues: foursquareVenueReducer,
  foursquareVenueCategories: foursquareVenueCategoryReducer,
  languages: langReducer,
  knownFors: knownForReducer,
  screenShots: screenShotsReducer,
  checkInProcesses: checkInProcessReducer,
  userKnownFors: userKnownForReducer,
  travelStats: travelStatsReducer,
  mentions: mentionReducer,
  seo: seoReducer,
  taggedMentions: taggedMentionReducer,
  google_maps: googleMapsReducer,
  posts: postsReducer,
  weather: weatherReducer,
  getTheres: getThereReducer,
  facebook: facebookReducer,
  providerFriends: providerFriendEvents,
  sync: syncReducer,
});

export default reducers;
