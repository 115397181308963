import React from 'react';
import {
  FaFacebook,
  FaInstagram,
  FaMapMarked,
  FaNewspaper,
  FaStar,
  FaTrophy,
  FaTwitter,
} from 'react-icons/fa';

export const PUBLIC_TOP_NAV_ITEMS = [
  {
    key: 'browseDestinations',
    label: 'Trip Planner',
    path: '/trip-planner',
  },
  {
    key: 'myTravelMap',
    label: 'My Travel Map',
    path: '/my-travel-map/edit',
  },
  {
    key: 'blog',
    label: 'Blog',
    path: '/blog',
  },
];

export const SOCIAL_LINKS = [
  {
    icon: FaFacebook,
    link: 'https://facebook.com/roadgoat/',
  },
  {
    icon: FaTwitter,
    link: 'https://twitter.com/goRoadGoat/',
  },
  {
    icon: FaInstagram,
    link: 'https://instagram.com/goRoadGoat/',
  },
];

export const AUTHENICATED_USER_NAVS = () => [
  {
    name: 'My Travel Map',
    path: '/my-travel-map/edit',
  },
  {
    name: 'Sync Travels',
    path: '/sync',
  },
  {
    name: 'Settings & Privacy',
    path: `/me/settings`,
    pathAs: `/me/settings`,
  },

  {
    name: 'Find Friends',
    path: '/find-friends',
  },
  {
    name: 'Leaderboard',
    path: '/leaderboard',
  },
];

export const PUBLIC_RESPONSIVE_NAVS = [
  {
    icon: <FaMapMarked />,
    label: 'My Travel Map',
    path: '/my-travel-map/edit',
  },
  {
    icon: <FaStar />,
    label: 'Trip Planner',
    path: '/trip-planner',
  },
  {
    icon: <FaNewspaper />,
    label: 'Blog',
    path: 'https://www.roadgoat.com/blog/',
  },
  {
    icon: <FaTrophy />,
    label: 'Travel Leaderboard',
    path: '/leaderboard',
  },
];

export const AUTHENICATED_PAGES_NAV = [
  {
    icon: <FaMapMarked />,
    label: 'My Travel Map',
    path: '/my-travel-map/edit',
  },
  {
    icon: <FaStar />,
    label: 'Trip Planner',
    path: '/trip-planner',
  },
  {
    icon: <FaNewspaper />,
    label: 'Blog',
    path: '/blog',
  },
];

export const AUTHENICATED_USER_RESPONSIVE_NAVS = slug => [
  {
    name: 'My Profile',
    path: `/travelers?userSlug=${slug}`,
    pathAs: `/travelers/${slug}`,
  },
  {
    name: 'Find Friends',
    path: '/find-friends',
  },
  {
    name: 'Leaderboard',
    path: '/leaderboard',
  },
];

export const SETTINGS_NAV = [{ label: 'My Settings', path: '/settings' }];

export const CAN_USE_DOM = typeof window !== 'undefined';

export const ACCESS_COOKIE = 'roadgoat_access';

export const COOKIE_DOMAIN = '/';

export const LOCAL_STORAGE_KEY = 'roadgoat_user';

export const HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const SYNC_PROVIDERS = {
  facebook: {
    name: 'Facebook',
    logo: '/images/shared/partner-logos/facebook.png',
  },
  google_oauth2: {
    name: 'Google Maps',
    logo: '/images/shared/partner-logos/google-maps.png',
  },
  foursquare: {
    name: 'Foursquare',
    logo: '/images/shared/partner-logos/foursquare.png',
  },

  concur: {
    name: 'SAP Concur',
    logo: '/images/shared/partner-logos/concur.jpeg',
  },
  tripit: {
    name: 'TripIt',
    logo: '/images/shared/partner-logos/tripit.png',
  },
  fitbit: {
    name: 'Fitbit',
    logo: '/images/shared/partner-logos/fitbit.png',
  },
};

export const COUNTRY_US_GEONAMES_ID = 6252001;
