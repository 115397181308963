import http from '../../api/requests/travelStats';
import normalize from '../../utils/normalizeJson';
import { events } from './reducers';

export const fetchLeaderboardResults = (keyWindow, isAuthenticated, siteWide) => dispatch => {
  dispatch(events.fetchStarted({ keyWindow, keyWindowMeta: { leaderboard: {} } }));
  return http
    .fetchLeaderboard(isAuthenticated, siteWide)
    .then(response => {
      let data;
      if (!isAuthenticated) {
        data = normalize({
          data: response.data.data,
          included: response.data.included,
        });
      } else {
        data = normalize(response.data);
      }
      return dispatch(
        events.changed({
          keyWindow,
          keyWindowMeta: { leaderboard: data },
        })
      );
    })
    .catch(err => {
      return dispatch(
        events.fetchFailed({ keyWindow, keyWindowMeta: { leaderboard: {} }, data: err.message })
      );
    });
};
