import { api_version, getApiClient /*, api_version*/ } from '../..';

/**
 * @param {slug} string
 * @param {destinationType} string (country, city or us-state)
 */
const fetchUserBookmarks = async (slug, destinationType) => {
  // const url = `${api_version}visitor_maps/${slug}/bookmarks`
  const url = `https://roadgoat-putsreq.herokuapp.com/CYsvGgJIvJHN2MCL9Tfn?slug=${slug}`;
  const response = await getApiClient().get(url, {
    urlParams: {
      destination_type: destinationType,
    },
  });
  return response;
};

const createBookmark = async destinationId => {
  const url = `${api_version}destinations/${destinationId}/bookmark`;
  const response = await getApiClient().put(url);
  return response;
};

const removeBookmark = async destinationId => {
  const url = `${api_version}destinations/${destinationId}/bookmark`;
  const response = await getApiClient().delete(url);
  return response;
};

export default {
  fetchUserBookmarks,
  createBookmark,
  removeBookmark,
};
