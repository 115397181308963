import { getApiClient } from '../..';
import { COUNTRY_US_GEONAMES_ID } from '../../../constants';

const fetchEvents = async destination => {
  const isUSACity =
    destination.relationships.country.attributes.geonames_id === COUNTRY_US_GEONAMES_ID;

  const cityName = isUSACity
    ? `${destination.attributes.short_name},${destination.relationships.state.attributes.name}`
    : destination.attributes.name;

  const response = await getApiClient().get('https://graph.facebook.com/search', {
    urlParams: {
      type: 'adgeolocation',
      location_types: "['city']",
      q: encodeURI(cityName),
      access_token: '519642741544020|550e17477a14f64c9037cd3f7035a021',
    },
  });

  return response;
};
export default {
  fetchEvents,
};
