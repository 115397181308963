import _get from 'lodash/get';
import _keys from 'lodash/keys';
import httpTraveler from '../../api/requests/travelers';
import http from '../../api/requests/user';
import { removeCookie } from '../../utils/cookie';
import normalize from '../../utils/normalizeJson';
import * as authActions from '../auth/actions';
import { events as destinationEvents } from '../destinations/reducers';
import { events as languageEvents } from '../languages/reducers';
import { events as providerFriendEvents } from '../providerFriends/reducers';
import { events as screenShotEvents } from '../screenShots/reducers';
import { events as travelStatsEvents } from '../travelStats/reducers';
import { events as syncEvents } from '../sync/reducers';
// import httpVisitor from '../../api/requests/visitors';
import { events } from './reducers';

export const errorTransformer = err => {
  const errorMessageByStatusCode = {
    '504': 'Service Unavailable',
    '500': 'Internal Server Error',
  };

  if (err.response) {
    if (err.response.data.errors) return err.response.data.errors;
    return { global: [errorMessageByStatusCode[`${err.response.status}`]] };
  }

  return { global: [err.message] };
};

const errorInterceptor = (dispatch, keyWindowErr = null) => err => {
  const keyWindow = keyWindowErr;
  dispatch(events.fetchFailed({ keyWindow, data: errorTransformer(err) }));
  throw err;
};

const normalizeUser = data => {
  const json = normalize(data);
  return { byId: json.user };
};

const normalizeUsersByKeyWindow = (data, keyWindow, customKeyWindowMeta = {}, additionalValues) => {
  const json = normalize(data);
  const keyWindowMeta = { ids: _keys(json.user), ...customKeyWindowMeta };
  return { keyWindow, keyWindowMeta, byId: json.user, ...additionalValues };
};

/*
 * Profile Settings
 */
export const fetchProfile = () => dispatch => {
  dispatch(events.fetchStarted());
  return http
    .fetchCurrentUser()
    .then(response => {
      const data = normalize(response.data);
      dispatch(events.changed({ byId: data.user }));
      if (data.travel_stats) dispatch(travelStatsEvents.changed({ byId: data.travel_stats }));
      dispatch(destinationEvents.changed({ byId: data.destination }));
      return dispatch(languageEvents.changed({ byId: data.language }));
    })
    .catch(errorInterceptor(dispatch));
};

export const updateProfile = userData => dispatch => {
  dispatch(events.fetchStarted());
  return http
    .updateUserProfile(userData)
    .then(response => dispatch(events.changed(normalizeUser(response.data))))
    .catch(errorInterceptor(dispatch));
};

export const deleteProfile = () => dispatch => {
  dispatch(events.fetchStarted());
  return http
    .deleteUser()
    .then(() => {
      removeCookie('fullToken');
    })
    .catch(errorInterceptor(dispatch));
};

/*
 * Password Settings
 */
export const requestPassword = ({ email }) => dispatch => {
  dispatch(events.fetchStarted());
  return http.requestPassword({ email }).catch(errorInterceptor(dispatch));
};

export const updatePassword = ({ password, key }) => dispatch => {
  dispatch(events.fetchStarted());
  return http.updatePassword({ password, key }).catch(errorInterceptor(dispatch));
};

/*
 * User Registration
 */
export const createUser = (
  { email, name, password },
  additionalParams = {},
  toastNotification
) => dispatch => {
  dispatch(events.fetchStarted());
  return http.createUser({ email, name, password }).then(() => {
    return dispatch(
      authActions.authenticate({ email, password }, additionalParams, toastNotification, true)
    );
  });
};

/*
 *  Avatar
 */
export const createAvatar = file => dispatch => {
  dispatch(events.fetchStarted());
  return http.createUserAvatar(file).then(response => {
    return dispatch(events.changed(normalizeUser(response.data)));
  });
};

export const editAvatar = ({ crop_x, crops_y, crop_w, crop_h }) => dispatch => {
  dispatch(events.fetchStarted());
  return http.editUserAvatar({ crop_x, crops_y, crop_w, crop_h }).then(response => {
    return dispatch(events.changed(normalizeUser(response.data)));
  });
};

export const deleteAvatar = () => dispatch => {
  dispatch(events.fetchStarted());
  return http.deleteUserAvatar().then(response => {
    return dispatch(events.changed(normalizeUser(response.data)));
  });
};

/*/*
 * Visitor
 */
export const fetchVisitorProfile = (slug, keyWindow) => dispatch => {
  dispatch(events.fetchStarted({ keyWindow }));
  return httpTraveler
    .fetchUser(slug)
    .then(response => {
      const data = normalize(response.data);
      const id = _keys(data.user)[0];

      dispatch(
        events.changed({
          keyWindow,
          keyWindowMeta: { id },
          byId: data.user,
        })
      );

      dispatch(destinationEvents.changed({ byId: data.destination }));
      dispatch(screenShotEvents.changed({ byId: data.screen_shot }));
      if (data.travel_stats) dispatch(travelStatsEvents.changed({ byId: data.travel_stats }));
      return dispatch(languageEvents.changed({ byId: data.language }));
    })
    .catch(errorInterceptor(dispatch, keyWindow));
};

export const fetchFollowers = (slug, page, keyWindow) => dispatch => {
  dispatch(events.fetchStarted({ keyWindow }));
  return httpTraveler
    .fetchUserFollowers(slug, page)
    .then(response => {
      dispatch(
        events.changed(
          normalizeUsersByKeyWindow(
            response.data,
            keyWindow,
            { pagination: response.data.meta },
            { append: page > 1 }
          )
        )
      );
    })
    .catch(errorInterceptor(dispatch, keyWindow));
};

export const fetchFollowings = (slug, page, keyWindow) => dispatch => {
  dispatch(events.fetchStarted({ keyWindow }));
  return httpTraveler
    .fetchUserFollowings(slug, page)
    .then(response =>
      dispatch(
        events.changed(
          normalizeUsersByKeyWindow(
            response.data,
            keyWindow,
            { pagination: response.data.meta },
            { append: page > 1 }
          )
        )
      )
    )
    .catch(errorInterceptor(dispatch, keyWindow));
};

export const queryVisitors = (provider, query, page, keyWindow) => dispatch => {
  const keyWindowMeta = provider !== 'roadgoat' ? { ids: [] } : {};
  dispatch(events.fetchStarted({ keyWindow, keyWindowMeta }));
  dispatch(providerFriendEvents.fetchStarted({ keyWindow, keyWindowMeta }));

  return httpTraveler
    .queryVisitors(provider, query, page)
    .then(response => {
      const json = normalize(response.data);
      const roadgoatUsers = _get(response.data, 'data', []).filter(user => user.type === 'user');
      const providerUsers = _get(response.data, 'data', []).filter(
        user => user.type === 'provider_friend'
      );
      const roadgoatUserIds = roadgoatUsers.map(destination => destination.id);
      const providerUserIds = providerUsers.map(destination => destination.id);

      dispatch(
        providerFriendEvents.changed({
          keyWindow,
          keyWindowMeta: {
            ids: providerUserIds,
            connected: true,
            pagination: response.data.meta,
          },
          byId: json.provider_friend || {},
        })
      );

      return dispatch(
        events.changed({
          keyWindow,
          keyWindowMeta: {
            ids: roadgoatUserIds,
            connected: true,
            pagination: response.data.meta,
          },
          byId: json.user || {},
          append: page > 1,
        })
      );
    })
    .catch(err => {
      const statusCode = _get(err, 'response.status', 500);
      const connected = statusCode !== 403;
      return dispatch(
        events.fetchFailed({
          keyWindow,
          keyWindowMeta: { connected, query },
          data: err.message,
        })
      );
    });
};

export const followRequest = id => dispatch => {
  dispatch(events.fetchStarted());
  return http.followUser(id, 'follow').catch(errorInterceptor(dispatch));
};

export const unfollowRequest = id => dispatch => {
  dispatch(events.fetchStarted());
  return http.followUser(id, 'unfollow').catch(errorInterceptor(dispatch));
};

export const inviteFriends = (emails, text, name) => dispatch => {
  dispatch(events.fetchStarted());
  return http.inviteFriends(emails, text, name).catch(errorInterceptor(dispatch));
};

export const contactUs = (from, name, subject, content) => dispatch => {
  dispatch(events.fetchStarted());
  return http.contactUs(from, name, subject, content).catch(errorInterceptor(dispatch));
};

export const fetchFriendsByDestination = (destination, query, keyWindow, keyword) => dispatch => {
  dispatch(events.fetchStarted({ keyWindow, keyWindowMeta: { ids: [], query } }));
  return http
    .fetchFriendsByDestination(destination, query, 'nil')
    .then(response => {
      return dispatch(
        events.changed(normalizeUsersByKeyWindow(response.data.data[keyword], keyWindow))
      );
    })
    .catch(err => {
      return dispatch(
        events.fetchFailed({
          keyWindow,
          keyWindowMeta: { ids: [], query },
          data: err.message,
        })
      );
    });
};

export const syncProvider = (provider, params = {}) => dispatch => {
  dispatch(syncEvents.update({
    provider,
    status: 'scheduled',
    progress: 0,
    message_type: 'sync_progress',
  }));

  return http.syncProvider(provider, params);
};

const PROVIDER_NAMES = {
  concur: 'SAP Concur',
};

export const disconnectProvider = (provider, toastNotification) => () => {
  const { addToast } = toastNotification;
  return http.disconnectProvider(provider).then(() => {
    const name = PROVIDER_NAMES[provider] || provider;
    addToast(`We've disconnected you from ${name}!`, {
      appearance: 'success',
      autoDismiss: true,
    });
  });
};

export const updateProfileState = (id, data) => dispatch => {
  return dispatch(
    events.changed({
      byId: {
        [id]: { attributes: data },
      },
    })
  );
};

export const businessSignUp = values => dispatch => {
  return http.businessSignUp(values).catch(errorInterceptor(dispatch));
};
