import _isEmpty from 'lodash/isEmpty';
import http from '../../api/requests/oauth';
import { removeCookie } from '../../utils/cookie';
import { getAuthTokenInfo } from '../../utils/getUserInfo';
import { events } from './reducers';

export const errorTransformer = err => {
  const getAuthErrorMessage = {
    invalid_grant: 'Email or password is invalid',
  };

  const message = err.response ? getAuthErrorMessage[err.response.data.error] : err.message;
  return { global: [message] };
};

// gets token from the api and stores it in the redux store and in cookie
export const authenticate = (
  { email, password },
  additionalParams = {},
  toastNotification,
  firstSignIn = false
) => dispatch => {
  const { addToast } = toastNotification;
  return http
    .createSession({ email, password })
    .then(() => getAuthTokenInfo())
    .then(tokenInfo => {
      const { provider } = additionalParams;
      const shouldConnectToProvider = !_isEmpty(provider);
      if (shouldConnectToProvider) {
        return connectProvider(provider, additionalParams, tokenInfo);
      }

      return { successURL: `/travelers/${tokenInfo.slug}`, tokenInfo };
    })
    .then(result => {
      const message = firstSignIn ? 'Welcome' : 'Welcome back!';
      addToast(message, {
        appearance: 'success',
        autoDismiss: true,
      });
      return dispatch(events.authenticated(result));
    });
};

export const connectProvider = (provider, params, tokenInfo) => {
  return http.connectProvider(provider, params).then(() => {
    return { successURL: `/integrations/${provider}`, tokenInfo };
  });
};

// executed server-side
export const authenticateByProvider = (query, context) => {
  const { provider } = query;

  return http.processProviderCallback(query, context).then(res => {
    if (res.data.authentication) {
      const source = res.data.source;
      const assertion = res.data.authentication.token;
      return http.createProviderSession({ assertion, provider }).then(response => {
        console.log('createProviderSession RESPONSE', response); // eslint-disable-line no-console
        return { source, tokenInfo: response.data };
      });
    }

    return res.data;
  });
};

// removing the token
export const deauthenticate = (token, toastNotification) => dispatch => {
  const { addToast } = toastNotification;
  return http.destroySession(token).then(() => {
    removeCookie('fullToken');
    addToast('Goodbye, come back soon!', {
      appearance: 'success',
      autoDismiss: true,
    });
    return dispatch(events.deauthenticated());
  });
};

export const setTokenInfo = tokenInfo => dispatch => dispatch(events.tokenInfoChanged(tokenInfo));
