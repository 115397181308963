import httpClient from './http';
import env from '../utils/environment';

const timeout = 60000;
const api_version = process.env.API_VERSION || '/api/v2/';
let roadgoatAPI;

const initializeHttpClient = context => {
  roadgoatAPI = new httpClient(env.getApiUrl(), env.isServerSide(), timeout, context);
};

const getApiClient = () => {
  if (!roadgoatAPI) initializeHttpClient();

  return roadgoatAPI;
};

export { initializeHttpClient, getApiClient, api_version };
