import _keys from 'lodash/keys';
import http from '../../api/requests/mentions';
import normalize from '../../utils/normalizeJson';
import { events as destinationEvents } from '../destinations/reducers';
import { events as knownForEvents } from '../knownFor/reducers';
import { events as photoEvents } from '../photos/reducers';
import { events as mentionTagEvents } from '../taggedMentions/reducers';
import { events } from './reducers';

export const fetchMention = (id, keyWindow) => dispatch => {
  dispatch(events.fetchStarted({ keyWindow }));
  return http
    .fetchMention(id)
    .then(response => {
      const data = normalize(response.data);
      dispatch(
        events.changed({
          keyWindow,
          keyWindowMeta: { id: _keys(data.mention)[0] },
          byId: data.mention,
        })
      );
      dispatch(mentionTagEvents.changed({ keyWindow, byId: data.tagged_mention }));
      dispatch(destinationEvents.changed({ keyWindow, byId: data.destination }));
      dispatch(photoEvents.changed({ keyWindow, byId: data.photo }));
      return dispatch(knownForEvents.changed({ keyWindow, byId: data.known_for }));
    });
};
