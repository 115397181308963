import _keys from 'lodash/keys';
import httpCheckIns from '../../api/requests/checkins';
import http from '../../api/requests/travelers';
import normalize from '../../utils/normalizeJson';
import httpDestinations from '../../api/requests/destinations';
import { events as destinationEvents } from '../destinations/reducers';
import { events } from './reducers';

// get user checkins
export const fetchCheckIns = (user, keyWindow) => dispatch => {
  dispatch(events.fetchStarted({ keyWindow, keyWindowMeta: { ids: [] } }));
  return http
    .fetchUserCheckIns(user.attributes.slug)
    .then(response => {
      const data = normalize(response.data);
      return dispatch(
        events.changed({
          keyWindow,
          keyWindowMeta: { ids: _keys(data.visit) },
          byId: data.visit,
        })
      );
    })
    .catch(err => {
      return dispatch(
        events.fetchFailed({ keyWindow, keyWindowMeta: { ids: [] }, data: err.message })
      );
    });
};

// get user checkins
export const getLeaderboard = keyWindow => dispatch => {
  dispatch(events.fetchStarted({ keyWindow, keyWindowMeta: { leaderboard: {} } }));
  return httpCheckIns
    .getLeaderboardData()
    .then(response => {
      return dispatch(
        events.changed({
          keyWindow,
          keyWindowMeta: {
            leaderboard: response.data.data,
          },
        })
      );
    })
    .catch(err => {
      return dispatch(
        events.fetchFailed({ keyWindow, keyWindowMeta: { leaderboard: {} }, data: err.message })
      );
    });
};

export const createCheckIn = (destination, rate, keyWindow, addToast) => dispatch => {
  dispatch(events.fetchStarted({ keyWindow }));

  dispatch(destinationEvents.addCachedCheckins({ destination }));
  dispatch(
    destinationEvents.changed({
      byId: {
        [destination.id]: {
          attributes: {
            check_in_count: destination.attributes.check_in_count + (rate > 0 ? 1 : 0),
            user_mark: rate && rate > 0 ? rate : null,
          },
        },
      },
    })
  );

  return httpCheckIns
    .createCheckIn(destination.id, rate)
    .then(response => {
      const checkInIds = _.get(response.data || {}, 'check_in_ids', {});
      const achievements = _.get(response.data || {}, 'achievements', []);

      achievements.forEach((achievment) => {
        addToast(achievment, {
          appearance: 'error',
          autoDismiss: true,
        })
      });

      return dispatch(destinationEvents.setAllCachedCheckins(checkInIds));
    })
    .then(() => httpDestinations.fetchDestination(destination.id))
    .then((response) => destinationEvents.changed({ byId: normalize(response.data).destination }))
    .catch(err => dispatch(events.fetchFailed({ keyWindow, data: err.message })));
};

export const removeCheckIn = (destination, keyWindow) => dispatch => {
  dispatch(events.fetchStarted({ keyWindow }));
  dispatch(destinationEvents.removeCachedCheckins({ destination }));

  return httpCheckIns
    .removeCheckIn(destination.id)
    .then(response => {
      dispatch(
        destinationEvents.changed({
          byId: {
            [destination.id]: {
              attributes: {
                check_in_count: destination.attributes.check_in_count - 1,
                user_mark: '',
              },
            },
          },
        })
      );

      return dispatch(destinationEvents.setAllCachedCheckins(response.data || {}));
    })
    .catch(err => dispatch(events.fetchFailed({ keyWindow, data: err.message })));
};
